import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../core/common/dataTable/index";
import apiDefinitions from "../../../api/apiDefinitions";

import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import FileUpload from "./fileUploader";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const CreateBudget = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(40), (val, index) => currentYear + index);

  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [year, setYear] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    year: "",
    file: "",
  });
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [initalError, setInitalError] = useState(false);
  const [subbudgetErrors, setSubbudgetErrors] = useState<{
    [key: number]: string;
  }>({});
  const [noSubbudgetsError, setNoSubbudgetsError] = useState<string>("");
  interface Subbudget {
    name: string;
    amount: string;
  }

  const [subbudgets, setSubbudgets] = useState<Subbudget[]>([]);

  const hasDataLoaded = useRef(false);

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  const handleClear = () => {
    setYear("");
    setBudget("");
    setUploadedFile([]);
    setClearFileUploader(true);
    setSubbudgets([]);
    setSubbudgetErrors({});
    setNoSubbudgetsError("");

    // Clear errors
    setFieldErrors({ year: "", file: "" });
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    console.log("file error", fieldErrors.file);
  }, [fieldErrors.file]);

  const handleSubmit = () => {
    let isvalid = true;

    if (year === "") {
      handleErrors({ year: "Year is required" });
      isvalid = false;
    }

    if (uploadedFile.length === 0) {
      handleErrors({ file: "File is required" });
      isvalid = false;
    }

    const newSubbudgetErrors: { [key: number]: string } = {};

    if (subbudgets.length === 0) {
      setNoSubbudgetsError("At least one sub-budget is required.");
      isvalid = false;
    } else {
      setNoSubbudgetsError(""); // Clear the "no sub-budgets" error if sub-budgets are added

      subbudgets.forEach((subbudget, index) => {
        let errorMessage = "";

        if (subbudget.name.trim() === "") {
          errorMessage = "Sub-Budget Type is required";
          isvalid = false;
        }

        if (subbudget.amount.trim() === "") {
          errorMessage += errorMessage
            ? " and Sub-Budget Value is required"
            : "Sub-Budget Value is required";
          isvalid = false;
        }

        if (errorMessage) {
          newSubbudgetErrors[index] = errorMessage;
        }
      });
    }

    setSubbudgetErrors(newSubbudgetErrors);

    if (fieldErrors.year === "" && fieldErrors.file === "" && isvalid) {
      // Collect all form data
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        // requestBudget: budget,
        year: year,
        requestedBy: employeeId,
        subBudgets: subbudgets,
      };

      console.log("Payload", payload);

      formData.append("budgetDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want Request New Annual Budget?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Requesting budget...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createBudget(token, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Requested Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Request Budget");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  interface Budget {
    id: string;
    requestBudget: string;
    year: string;
    approvedAmount: string;
    status: { id: number; name: string; color: string }[];
    startDate: string;
    endDate: string;
  }

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Budget) => JSX.Element;
  }

  const columns: Column[] = [
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Approved Budget (LKR)",
      dataIndex: "approvedAmount",
    },
    {
      title: "Requested Budget (LKR)",
      dataIndex: "requestBudget",
    },
    {
      title: "Requested Date",
      dataIndex: "requestDate",
      render: (requestDate: string | null) => (
        <span>{requestDate ? formatDate(requestDate) : "N/A"}</span>
      ),
    },
    {
      title: "Approved Date",
      dataIndex: "approveRejectDate",
      render: (approveRejectDate: string | null) => (
        <span>{approveRejectDate ? formatDate(approveRejectDate) : "N/A"}</span>
      ),
    },
    {
      title: "Mkting. Status",
      dataIndex: "status",
      render: (status: { id: number; name: string; color: string }[]) => {
        if (status.length > 0) {
          const { name, color } = status[0]; // Extract the first status
          return (
            <div>
              <span className={`badge badge-pill badge-status bg-${color}`}>
                {name}
              </span>
            </div>
          );
        }
        return <span>No Status</span>; // Handle case where status is empty
      },
    },
    {
      title: "Approval Status",
      dataIndex: "approveRejectStatus",
      render: (approveRejectStatus: string | null) => {
        const color = "info"; // Hard code the color
        return (
          <div>
            <span className={`badge badge-pill badge-status bg-${color}`}>
              {approveRejectStatus ?? "Marketing Pending"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record: Budget) => (
        <div className="action-icons d-flex gap-2">
          {/* Eye Icon */}
          <Link
            to={`/budget/subBudgets/${record.id}`}
            className="text-info"
            title="View Sub-Budget"
          >
            <i
              className="ti ti-chart-pie"
              style={{
                fontSize: "1.8rem",
                marginLeft: "10px", // Adjust this value as needed
              }}
            ></i>
          </Link>
          <Link
            to={`/budget/approvedBudgets/viewBudget/${record.id}`}
            className="text-info"
            title="View Details"
          >
            <i
              className="ti ti-eye"
              style={{
                fontSize: "1.8rem",
                marginLeft: "10px",
              }}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions.getApprovedBudgets(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setCampaignList(response.data.data);
            toast.dismiss(toastId);
            toast.success("Data Loaded", {
              duration: 5000,
            });
          } else {
            toast.dismiss(toastId);
            toast.error("Failed to Load Data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    refreshCampaigns();
  }, []);

  useEffect(() => {
    const budgetRegex = /^\d+(\.\d{2})$/;
    if (budget === "") {
      if (initalError) {
        handleErrors({ budget: "Budget is required" });
      } else {
        setInitalError(true);
      }
    } else if (!budgetRegex.test(budget)) {
      handleErrors({
        budget: "Budget must be a number with exactly two decimal places",
      });
    } else {
      handleErrors({ budget: "" });
    }
  }, [budget]);

  useEffect(() => {
    if (year === "") {
      if (initalError) {
        handleErrors({ year: "Year is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ year: "" });
    }
  }, [year]);

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  useEffect(() => {
    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleClear);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleClear);
      }
    };
  }, []);

  const handleAddSubbudget = () => {
    setSubbudgets([...subbudgets, { name: "", amount: "" }]);
    setNoSubbudgetsError(""); // Clear the "no sub-budgets" error
    setSubbudgetErrors({ ...subbudgetErrors, [subbudgets.length]: "" }); // Clear error for the new sub-budget
  };

  // Function to handle changes in subbudget name and value
  const handleSubbudgetChange = (
    index: number,
    field: keyof Subbudget,
    amount: string
  ) => {
    const updatedSubbudgets: Subbudget[] = [...subbudgets];
    updatedSubbudgets[index][field] = amount;
    setSubbudgets(updatedSubbudgets);
  };

  // Function to handle deleting a subbudget field
  const handleDeleteSubbudget = (index: number): void => {
    const updatedSubbudgets: Subbudget[] = subbudgets.filter(
      (_, i) => i !== index
    );
    setSubbudgets(updatedSubbudgets);

    // Remove the error for the deleted sub-budget
    const updatedErrors = { ...subbudgetErrors };
    delete updatedErrors[index];
    setSubbudgetErrors(updatedErrors);

    // If no sub-budgets are left, show the "no sub-budgets" error
    if (updatedSubbudgets.length === 0) {
      setNoSubbudgetsError("At least one sub-budget is required.");
    }
  };

  const calculateTotalBudget = (subbudgets: Subbudget[]): string => {
    let total = 0;
    subbudgets.forEach((subbudget) => {
      const amount = parseFloat(subbudget.amount);
      if (!isNaN(amount)) {
        total += amount;
      }
    });
    return total.toFixed(2); // Ensure the total is formatted to two decimal places
  };

  useEffect(() => {
    const totalBudget = calculateTotalBudget(subbudgets);
    setBudget(totalBudget);
  }, [subbudgets]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Annual Budget</h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="campaign-tab">
                <ul className="nav">
                  <li>
                    <Link
                      to={route.createBudget}
                      className={
                        isLinkActive(route.createBudget) ? "active" : ""
                      }
                    >
                      Approved Budgets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.PenddingBudgets}
                      className={
                        isLinkActive(route.PenddingBudgets) ? "active" : ""
                      }
                    >
                      Pending Budgets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.RejectedBudgets}
                      className={
                        isLinkActive(route.RejectedBudgets) ? "active" : ""
                      }
                    >
                      Rejected Budgets
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Campaign Tab */}
              <div className="card">
                <div className="card-header">
                  {/* Search */}
                  <div className="row align-items-center">
                    <div className="row align-items-center justify-content-between">
                      {/* Search Bar aligned to the Left */}
                      <div className="col-sm-6">
                        {/* <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Campaign"
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                        </div> */}
                      </div>

                      {/* Button aligned to the Right */}
                      <div
                        className="modal fade"
                        id="con-close-modal"
                        tabIndex={-1}
                        aria-labelledby="con-close-modal-label"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="con-close-modal-label"
                              >
                                Request New Budget for Year
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body p-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="field-1"
                                      className="form-label"
                                    >
                                      Year
                                    </label>
                                    <select
                                      className="form-control"
                                      id="field-1"
                                      value={year}
                                      onChange={(e) => setYear(e.target.value)}
                                    >
                                      <option value="" disabled selected>
                                        Select a year
                                      </option>
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                    {fieldErrors.year !== "" ? (
                                      <div className="text-danger">
                                        {fieldErrors.year}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <label
                                    htmlFor="field-2"
                                    className="form-label"
                                  >
                                    Estimated Budget
                                  </label>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      LKR
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder="Estimated Budget"
                                      aria-describedby="basic-addon1"
                                      value={budget}
                                      disabled
                                      onChange={(e) =>
                                        setBudget(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="divider"></div>

                                <div className="mt-2">
                                  <h6>Sub-Budgets</h6>
                                  {noSubbudgetsError && (
                                    <div className="text-danger mb-2">
                                      {noSubbudgetsError}
                                    </div>
                                  )}
                                  {subbudgets.map((subbudget, index) => (
                                    <div
                                      key={index}
                                      className="d-flex mb-2 mt-2"
                                    >
                                      <div className="row">
                                        <div className="col-md-5">
                                          <label
                                            htmlFor="field-1"
                                            className="form-label"
                                          >
                                            Sub-Budget Type
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Subbudget Name"
                                            value={subbudget.name}
                                            onChange={(e) =>
                                              handleSubbudgetChange(
                                                index,
                                                "name",
                                                e.target.value
                                              )
                                            }
                                          />
                                          {subbudgetErrors[index] && (
                                            <div className="text-danger">
                                              {subbudgetErrors[index]}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-md-5">
                                          <label
                                            htmlFor="field-1"
                                            className="form-label"
                                          >
                                            Sub-Budget Value
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control me-2"
                                            placeholder="Subbudget Value"
                                            value={subbudget.amount}
                                            onChange={(e) =>
                                              handleSubbudgetChange(
                                                index,
                                                "amount",
                                                e.target.value
                                              )
                                            }
                                          />
                                          {subbudgetErrors[index] && (
                                            <div className="text-danger">
                                              {subbudgetErrors[index]}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-md-2">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            style={{ marginTop: "30px" }}
                                            onClick={() =>
                                              handleDeleteSubbudget(index)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <button
                                    type="button"
                                    className="btn btn-success mt-2"
                                    onClick={handleAddSubbudget}
                                  >
                                    Add Sub-Budget
                                  </button>
                                </div>

                                <div className="divider"></div>
                                <div className="modal-body">
                                  <FileUpload
                                    onFileSelect={handleFileSelect}
                                    clear={clearFileUploader}
                                  />
                                  {fieldErrors.file !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.file}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-cancel waves-effect"
                                data-bs-dismiss="modal"
                                onClick={handleClear}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleSubmit}
                              >
                                Add Budget
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 text-end">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mt-1 me-1"
                          data-bs-toggle="modal"
                          data-bs-target="#con-close-modal"
                          onClick={() => {
                            setInitalError(false);
                            setFieldErrors({ file: "" });
                          }}
                        >
                          Request New Budget
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 mt-3">
                        <div className="table-responsive custom-table">
                          <Table dataSource={campaignList} columns={columns} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default CreateBudget;
