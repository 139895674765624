import React, { useEffect, useState, useRef } from "react";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { OverlayTrigger, Popover } from "react-bootstrap";
import CollapseHeader from "../../../../core/common/collapse-header";
import apiDefinitions from "../../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
const route = all_routes;

const AssignedChannels = () => {
  const hasDataLoaded = useRef(false);
  const navigate = useNavigate();
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }
  const [employeeId, setEmployeeId] = useState("");
  const [profilePic, setProfilePic] = useState("");
  interface Campaign {
    id: string;
    name: string;
    channel: string;
    allocatedBudget: string;
    startDate: string;
    endDate: string;
    status: string;
  }
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  interface Employee {
    serial_number: string;
    name: string;
    [key: string]: any;
  }
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [updatedEmployeeList, setUpdatedEmployeeList] = useState<Employee[]>(
    []
  );
  interface EmployeeDetails {
    nameWithInitials: string;
    [key: string]: any;
  }
  const [employeeDetails, setEmployeeDetails] =
    useState<EmployeeDetails | null>(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.serial_number);
      // setEmployeeName(decodedToken.name);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getProfilePic(employeeId)
        .then((response: any) => {
          if (response.data.code === 200) {
            setEmployeeDetails(response.data.data);
            console.log("Employees List", response.data.data);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getMarketingList()
        .then((response: any) => {
          if (response.data.code === 200) {
            const fetchedEmployeeList = response.data.data;

            // Filter the list to exclude the currently logged-in user
            const filteredList = fetchedEmployeeList.filter(
              (employee: any) => employee.serial_number !== employeeId
            );

            // Update both states
            setEmployeeList(fetchedEmployeeList);
            setUpdatedEmployeeList(filteredList);

            console.log("Employee Details", fetchedEmployeeList);
            console.log("Filtered List", filteredList);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Fetching assigned campaign information");
        apiDefinitions
          .getAssignedCampaignInformation(token, decodedToken.serial_number)
          .then((response: any) => {
            if (response.data.code === 200) {
              toast.dismiss(toastId);
              // setCount(response.data.data);
              console.log("Assigned Campaign Information", response.data.data);
              setCampaignList(response.data.data);
            } else {
              toast.dismiss(toastId);
              // setCount(0);
              console.error("Failed to fetch assigned campaign information");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  const renderNamePopover = (name: string) => (
    <Popover id="popover-basic">
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <h4 className="page-title">
                    {employeeDetails?.nameWithInitials}'s Assigned Channels
                  </h4>
                </div>
                <div className="col-sm-8 text-sm-end">
                  <div className="head-icons">
                    <CollapseHeader />
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            {/* /Settings Menu */}
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                {/* Settings Info */}
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="border-bottom mb-3 pb-3">
                        <h5 className="fw-semibold mb-1">
                          Employee Information
                        </h5>
                        {/* <p>Provide the information below</p> */}
                      </div>
                      <div className="mb-3">
                        <div className="profile-upload">
                          <div className="d-flex align-items-center mb-4">
                            <img
                              src={employeeDetails?.profilePictureUrl || ""}
                              alt="Profile"
                              style={{
                                width: "120px",
                                height: "120px",
                                borderRadius: "5%",
                                objectFit: "cover",
                              }}
                            />
                            <div className="ms-4">
                              <h5>{employeeDetails?.nameInFull}</h5>
                              <p className="text-muted">
                                {employeeDetails?.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </form>
                    <div className="col-md-12">
                      <div className="card analytics-card">
                        <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
                          <h5>
                            <i className="ti ti-grip-vertical" /> Assigned
                            Campaign Channel Information
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="">
                            <div
                              id="analytic-contact_wrapper"
                              className="dataTables_wrapper dt-bootstrap5 no-footer"
                            >
                              <div className="row">
                                <div className="col-sm-12 col-md-6" />
                                <div className="col-sm-12 col-md-6" />
                              </div>
                              <div className="row dt-row">
                                <div className="col-sm-12 table-responsive">
                                  <table
                                    className="table table-striped no-footer"
                                    id="analytic-contact"
                                    // style={{ width: 635 }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: "174.333px" }}
                                        >
                                          Campaign Name
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: 131 }}
                                        >
                                          Assigned Channel
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: 131 }}
                                        >
                                          Allocated Budget (LKR)
                                        </th>

                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: 96 }}
                                        >
                                          Start Date
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: "152.333px" }}
                                        >
                                          End Date
                                        </th>
                                        <th
                                          className="sorting_disabled"
                                          rowSpan={1}
                                          colSpan={1}
                                          style={{ width: "152.333px" }}
                                        >
                                          Status
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {campaignList.map((campaign) => (
                                        <tr key={campaign.id} className="odd">
                                          <td>
                                            {campaign.name.length > 20 ? (
                                              <OverlayTrigger
                                                trigger={["hover", "focus"]}
                                                placement="top"
                                                overlay={renderNamePopover(
                                                  campaign.name
                                                )}
                                              >
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {campaign.name.substring(
                                                    0,
                                                    20
                                                  )}
                                                  ...
                                                </span>
                                              </OverlayTrigger>
                                            ) : (
                                              campaign.name
                                            )}
                                          </td>

                                          <td>{campaign.channel}</td>
                                          <td>{campaign.allocatedBudget}</td>
                                          <td>{campaign.startDate}</td>
                                          <td>{campaign.endDate}</td>
                                          <td>
                                            <Tag color="green">ACTIVE</Tag>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Settings Info */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedChannels;
