import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../../core/common/dataTable/index";
import apiDefinitions from "../../../../api/apiDefinitions";

import { all_routes } from "../../../router/all_routes";
import CollapseHeader from "../../../../core/common/collapse-header";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { Pagination, Form } from "react-bootstrap";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";

import { useNavigate } from "react-router-dom";

const SubBudgetApproval = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(10), (val, index) => currentYear + index);
  const { id } = useParams<{ id: string }>();
  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [year, setYear] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    subBudgetName: "",
    budget: "",
    file: "",
  });
  const [employeeId, setEmployeeId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [initalError, setInitalError] = useState(false);

  const hasDataLoaded = useRef(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");
  const [leadPriority, setLeadPriority] = useState("");
  const [budegtYear, setBudgetYear] = useState("");
  const [subBudgetId, setSubBudgetId] = useState<number | null>(null);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [description, setDescription] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [subBudgetName, setSubBudgetName] = useState("");
  const [requestingBudget, setRequestingBudget] = useState("");
  const [subBudgetDescription, setSubBudgetDescription] = useState("");
  const [approveRejectNote, setApproveRejectNote] = useState("");
  const [getSubBudgetName, getSetSubBudgetName] = useState("");
  const [subBudgetNameIsValid, setSubBudgetNameIsValid] = useState(false);
  const [requestingBudgetIsValid, setRequestingBudgetIsValid] = useState(false);
  const [subBudgetDescriptionIsValid, setSubBudgetDescriptionIsValid] =
    useState(false);
  const [approveRejectionNoteIsvalid, setApproveRejectNoteIsvalid] =
    useState(false);
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [isNoteValid, setIsNoteValid] = useState(false);
  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [subBudgetAmount, setSubBudgetAmount] = useState("");
  const [subBudgetApprovalNote, setSubBudgetApprovalNote] = useState("");

  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);

  interface Budget {
    id: number;
    requestBudget: number;
    year: string;
    requestedBy: string;
    fileJustifications: string[];
    approvedAmount: number;
    approvedBy: string;
    startDate: string;
    endDate: string;
    rejectionNote: string | null;
    reason: string;
    status: {
      id: number;
      name: string;
      color: string;
    }[];
  }

  interface Lead {
    id: number;
    name: string;
    requestAmount: string;
    requestBy: string;
    status: string;
    budget: Budget;
    requestDate: string;
    approveRejectDate: string;
    presidentApproveRejectDate: string;
    subBudgetType: {
      name: string;
    };
  }

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  const handleClear = () => {
    setYear("");
    setBudget("");
    setUploadedFile([]);

    // Clear errors
    setFieldErrors({ year: "", budget: "", file: "" });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
      setRoleId(decodedToken.role_id);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    console.log("file error", fieldErrors.file);
  }, [fieldErrors.file]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date in YYYY-MM-DD format
  };

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    // if (!roleId) {
    //   console.error("No role_id found in token.");
    //   return;
    // }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    const offset = currentPage - 1;

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");

        let apiCall;
        if (Number(roleId) === 2) {
          apiCall = apiDefinitions.getApprovalForMarketing(
            token,
            2,
            offset,
            itemsPerPage
          );
        } else if (Number(roleId) === 1) {
          apiCall = apiDefinitions.getApprovalForPresident(
            token,
            false,
            offset,
            itemsPerPage
          );
        } else if (Number(roleId) === 3) {
          apiCall = apiDefinitions.getApprovalForFinance(
            token,
            false,
            offset,
            itemsPerPage
          );
        } else {
          toast.dismiss(toastId);
          console.error("Unauthorized role");
          return;
        }

        apiCall.then((response) => {
          if (response.data.code === 200) {
            const data = response.data.data;
            if (data) {
              setTotalPages(data.totalPages || 0);
              setCurrentPage((data.pageable?.pageNumber || 0) + 1);
              setLeads(data.content || []);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("No data available");
            }
          } else {
            toast.dismiss(toastId);
            toast.error("Failed to Load Data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    refreshCampaigns();
  }, [itemsPerPage, currentPage, roleId, refresh]);

  const getBudgetDetails = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getBudgetByBudgetId(token, id).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Sub Budgettttt: ", response.data.data[0].year);
            setBudgetYear(response.data.data[0].year);
          } else {
            toast.error("Failed to Load Data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  useEffect(() => {
    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleClear);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleClear);
      }
    };
  }, []);

  const handleSearch = () => {
    setSearchClicked(!searchClicked);
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // const handleClose = () => {
  //   setSearchClicked(!searchClicked);
  //   setSearchKey("");
  //   setLeadPriority("");
  //   setLeadStatus("");
  // };

  useEffect(() => {
    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleClear);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleClear);
      }
    };
  }, []);

  const handleSubBudgetApproval = (subBudgetId: number) => {
    setSubBudgetId(subBudgetId);
  };

  const handleSubmit = () => {
    console.log("Submitted");
    let isValid = true;

    if (approvalStatus === "") {
      setIsStatusValid(true);
      isValid = false;
    } else {
      setIsStatusValid(false);
    }

    if (description === "") {
      setIsNoteValid(true);
      isValid = false;
    } else {
      setIsNoteValid(false);
    }

    if (isValid) {
      const payload = {
        approveRejectBy: employeeId,
        approveRejectNote: description,
      };

      Swal.fire({
        title: "Are you sure?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Submit",
        denyButtonText: `Don't Submit`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Submitting Approval...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .approveOrRejectSubBudgetByMarketing(
                  token,
                  subBudgetId,
                  approvalStatus,
                  payload
                )
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Approval Submitted Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Submit Approval");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  useEffect(() => {
    if (!subBudgetId) {
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    // if (!hasImageLoaded.current) {
    //   hasImageLoaded.current = true;
    //   return;
    // }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getJustificationForSubBudget(token, subBudgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              // Ensure you access the correct key
              setUploadAttendaceDocuments(response.data.data || []);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [subBudgetId]);

  useEffect(() => {
    if (Array.isArray(uploadAttendaceDocuments)) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is not an array
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments]);

  const handlePresidentApprove = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Approve it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const loadingToast = toast.loading("Approving Sub-Budget...");
        apiDefinitions
          .approveSubBudgetByPresident(token, subBudgetId, true)
          .then((response) => {
            if (response.data.code === 200) {
              toast.dismiss(loadingToast);
              toast.success("Sub-Budget Approved Successfully");
              setRefresh(!refresh);
              window.location.reload();
            } else {
              toast.dismiss(loadingToast);
              toast.error("Failed to Approve Sub-Budget");
            }
          });
      }
    });
  };

  const handlePresidentReject = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Reject it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const loadingToast = toast.loading("Rejecting Sub-Budget...");
        apiDefinitions
          .approveSubBudgetByPresident(token, subBudgetId, false)
          .then((response) => {
            if (response.data.code === 200) {
              toast.dismiss(loadingToast);
              toast.success("Sub-Budget Rejected Successfully");
              setRefresh(!refresh);
              window.location.reload();
            } else {
              toast.dismiss(loadingToast);
              toast.error("Failed to Reject Sub-Budget");
            }
          });
      }
    });
  };

  const handleFinanceApprove = () => {
    let isValid = true;

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (approveRejectNote === "") {
      setApproveRejectNoteIsvalid(true);
      isValid = false;
    } else {
      setApproveRejectNoteIsvalid(false);
    }

    const payload = {
      FinanceApproveRejectNote: approveRejectNote,
    };

    if (isValid) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Approve it!",
        cancelButtonText: "No, cancel!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          const loadingToast = toast.loading("Approving Sub-Budget...");
          apiDefinitions
            .approveSubBudgetByFinance(token, subBudgetId, true, payload)
            .then((response) => {
              if (response.data.code === 200) {
                toast.dismiss(loadingToast);
                toast.success("Sub-Budget Approved Successfully");
                setRefresh(!refresh);
                window.location.reload();
              } else {
                toast.dismiss(loadingToast);
                toast.error("Failed to Approve Sub-Budget");
              }
            });
        }
      });
    }
  };

  //   const handleFinanceReject = () => {
  //     const token = sessionStorage.getItem("token");
  //     if (!token) {
  //       console.error("No token found. Please log in again.");
  //       navigate(route.login);
  //       return;
  //     }

  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, Reject it!",
  //       cancelButtonText: "No, cancel!",
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         const loadingToast = toast.loading("Rejecting Sub-Budget...");
  //         apiDefinitions
  //           .rejectSubBudgetByFinance(token, subBudgetId, false)
  //           .then((response) => {
  //             if (response.data.code === 200) {
  //               toast.dismiss(loadingToast);
  //               toast.success("Sub-Budget Rejected Successfully");
  //               setRefresh(!refresh);
  //               window.location.reload();
  //             } else {
  //               toast.dismiss(loadingToast);
  //               toast.error("Failed to Reject Sub-Budget");
  //             }
  //           });
  //       }
  //     });
  //   };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!subBudgetId) {
      console.error("No sub budget id found");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getSubBudgetDetailsById(token, subBudgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              console.log(
                "Sub Budget Year: ",
                response.data.data[0].budget.year
              );
              setSubBudgetDescription(response.data.data[0].description);
              setSubBudgetAmount(response.data.data[0].requestAmount);
              getSetSubBudgetName(response.data.data[0].name);
              setSubBudgetApprovalNote(response.data.data[0].approveRejectNote);
            } else {
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [subBudgetId]);

  const handleClose = () => {
    setSubBudgetId(null);
    setApprovalStatus("");
    setDescription("");
    setAttendaceDocuments([]);
    setSubBudgetDescription("");
    setApproveRejectNote("");
    setSubBudgetAmount("");
    getSetSubBudgetName("");
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Annual Sub-Budget Approval</h4>
                  </div>
                  <div className="col-8 text-end">
                    <Link
                      to="/budget/approvedBudgets"
                      className="btn btn-primary"
                    >
                      <i
                        className="feather-arrow-left"
                        style={{ fontSize: "17px", marginRight: "5px" }}
                      />
                      Back to list
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      {/* <div className="card-title">View All Leads 2</div> */}
                      <div className="row">
                        <div className="col-md-1 mt-3">
                          <Form.Select
                            value={itemsPerPage}
                            onChange={(event) =>
                              setItemsPerPage(parseInt(event.target.value, 10))
                            }
                            aria-label="Select number of items per page"
                            className="w-auto"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table text-nowrap table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sub-Budget Name</th>
                              <th scope="col">Budget Year</th>
                              <th scope="col">Sub-Budget Type</th>
                              <th scope="col">Requested Amount (LKR)</th>
                              <th scope="col">
                                {Number(roleId) === 1
                                  ? "Mrkt. Approved Date & Time"
                                  : Number(roleId) === 3
                                  ? "President Approved Date & Time"
                                  : ""}
                              </th>
                              <th scope="col">Requested Date & Time</th>

                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leads.length > 0 ? (
                              leads.map((lead, index) => (
                                <tr key={index}>
                                  <td>{lead.name}</td>
                                  <td>{lead.budget.year}</td>
                                  <td>{lead.subBudgetType.name}</td>
                                  <td>{lead.requestAmount}</td>
                                  <td>
                                    {Number(roleId) === 1
                                      ? lead.approveRejectDate
                                        ? lead.approveRejectDate
                                            .split(" ")
                                            .slice(1, 4)
                                            .join(" ")
                                        : ""
                                      : Number(roleId) === 3
                                      ? lead.presidentApproveRejectDate
                                        ? lead.presidentApproveRejectDate
                                            .split(" ")
                                            .slice(1, 4)
                                            .join(" ")
                                        : ""
                                      : ""}
                                  </td>
                                  <td>
                                    {lead.requestDate
                                      ? lead.requestDate
                                          .split(" ")
                                          .slice(1, 4)
                                          .join(" ")
                                      : "N/A"}
                                  </td>
                                  <td>
                                    <div className="hstack gap-2 fs-15">
                                      <Link
                                        to="#"
                                        className="btn btn-info"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_faq"
                                        onClick={() => {
                                          handleSubBudgetApproval(lead.id);
                                        }}
                                      >
                                        <i
                                          className="feather-check-circle"
                                          style={{ fontSize: "17px" }}
                                        />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={9} className="text-center">
                                  No Data Available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination className="mt-3 justify-content-center">
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />
                        {Array.from({ length: totalPages }, (_, index) => (
                          <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="add_faq" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Sub-Budget Approval for {getSubBudgetName}
              </h5>
              <div className="d-flex align-items-center mod-toggle">
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <i className="ti ti-x" />
                </button>
              </div>
            </div>
            <form>
              <div className="modal-body">
                <p>
                  <strong>Sub Budget Description:</strong>{" "}
                  {subBudgetDescription}
                </p>
                <p>
                  <strong>Marketing Approval Note:</strong>{" "}
                  {subBudgetApprovalNote}
                </p>
                <p>
                  <strong>Sub Budget Amount:</strong> {subBudgetAmount}
                </p>
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%", // Ensure it takes full height of the row
                  }}
                >
                  <div className="card-header">
                    <h5 className="card-title">Justification Documents</h5>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto", // Make scrollable if content overflows
                    }}
                  >
                    <div className="row">
                      {attendaceDocuments.map(
                        (data: {
                          id: React.Key | null | undefined;
                          fileType: string;
                          fileName: any;
                          filePath: string | URL | undefined;
                        }) => (
                          <div className="col-md-6 mb-4" key={data.id}>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              <div className="mb-3">
                                {data.fileType === "pdf" ? (
                                  <ImageWithBasePath
                                    src="assets/img/pdf.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{
                                      width: "35px",
                                      height: "auto",
                                    }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "image" ? (
                                  <ImageWithBasePath
                                    src="assets/img/picture.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{
                                      width: "35px",
                                      height: "auto",
                                    }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "doc" ? (
                                  <ImageWithBasePath
                                    src="assets/img/doc.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{
                                      width: "35px",
                                      height: "auto",
                                    }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "xls" ? (
                                  <ImageWithBasePath
                                    src="assets/img/xls.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{
                                      width: "35px",
                                      height: "auto",
                                    }} // Adjust the width and height as needed
                                  />
                                ) : null}
                              </div>
                              <div className="text-center">
                                <h6
                                  className="mb-2 text-truncate"
                                  style={{
                                    maxWidth: "150px", // Adjust width as per your layout
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={data.fileName} // Show full name on hover
                                >
                                  {data.fileName || "Placeholder Name"}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-center">
                                <a
                                  href={data.filePath?.toString()} // File URL
                                  download={data.fileName} // Download file with its name
                                  className="btn btn-outline-primary me-2 btn-sm"
                                >
                                  Download File
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {Number(roleId) === 3 ? (
                      <div className="col-md-12 mt-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustom02"
                        >
                          Description
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              approveRejectionNoteIsvalid ? "is-invalid" : ""
                            }`}
                            id="validationCustom02"
                            placeholder="Description"
                            value={approveRejectNote}
                            onChange={(e) =>
                              setApproveRejectNote(e.target.value)
                            }
                            required
                          />
                          {approveRejectionNoteIsvalid && (
                            <div className="invalid-feedback">
                              Please enter a description
                            </div>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>

                {Number(roleId) === 2 ? (
                  <>
                    <div className="mb-3">
                      <label
                        className="form-label"
                        htmlFor="validationCustomContactMethod"
                      >
                        Approval Note
                      </label>
                      <textarea
                        rows={1}
                        cols={5}
                        className={`form-control ${
                          isNoteValid ? "is-invalid" : ""
                        }`}
                        placeholder="Enter text here"
                        defaultValue={""}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                      {isNoteValid && (
                        <div className="invalid-feedback">
                          Please enter a description.
                        </div>
                      )}
                    </div>

                    <div className="mb-3">
                      {" "}
                      <label
                        className="form-label"
                        htmlFor="validationCustomContactMethod"
                      >
                        {" "}
                        Approval Status{" "}
                      </label>{" "}
                      <select
                        className={`form-select ${
                          isStatusValid ? "is-invalid" : ""
                        }`}
                        id="validationCustomContactMethod"
                        value={approvalStatus}
                        onChange={(e) => setApprovalStatus(e.target.value)}
                        required
                      >
                        {" "}
                        <option value="">Select an option</option>{" "}
                        <option value="approved">Approve </option>{" "}
                        <option value="rejected">Reject</option>{" "}
                      </select>{" "}
                      {isStatusValid && (
                        <div className="invalid-feedback">
                          Please select an approval status.
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </div>

              <div className="modal-footer">
                <div className="d-flex align-items-center justify-content-end m-0">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Link>
                  {Number(roleId) === 2 ? (
                    <button
                      type="button"
                      // data-bs-dismiss="modal"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </button>
                  ) : null}
                  {Number(roleId) === 1 ? (
                    <>
                      <button
                        type="button"
                        // data-bs-dismiss="modal"
                        className="btn btn-warning"
                        onClick={() => {
                          handlePresidentApprove();
                        }}
                      >
                        Approve
                      </button>

                      <button
                        style={{ marginLeft: "10px" }}
                        type="button"
                        // data-bs-dismiss="modal"
                        className="btn btn-primary"
                        onClick={() => {
                          handlePresidentReject();
                        }}
                      >
                        Reject
                      </button>
                    </>
                  ) : null}

                  {Number(roleId) === 3 ? (
                    <>
                      <button
                        type="button"
                        // data-bs-dismiss="modal"
                        className="btn btn-primary"
                        onClick={() => {
                          handleFinanceApprove();
                        }}
                      >
                        Approve
                      </button>

                      {/* <button
                        style={{ marginLeft: "10px" }}
                        type="button"
                        // data-bs-dismiss="modal"
                        className="btn btn-primary"
                        onClick={() => {
                          handleFinanceReject();
                        }}
                      >
                        Reject
                      </button> */}
                    </>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubBudgetApproval;
