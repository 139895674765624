import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../core/common/dataTable/index";
import apiDefinitions from "../../../api/apiDefinitions";

import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";

import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { Pagination, Form } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

const RequestedSubBudget = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(10), (val, index) => currentYear + index);
  const { id } = useParams<{ id: string }>();
  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [year, setYear] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    year: "",
    budget: "",
    file: "",
  });
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [initalError, setInitalError] = useState(false);

  const hasDataLoaded = useRef(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");
  const [leadPriority, setLeadPriority] = useState("");
  const [refresh, setRefresh] = useState(false);

  interface Budget {
    id: number;
    requestBudget: number;
    year: string;
    requestedBy: string;
    fileJustifications: string[];
    approvedAmount: number;
    approvedBy: string;
    startDate: string;
    endDate: string;
    rejectionNote: string | null;
    reason: string;
    status: {
      id: number;
      name: string;
      color: string;
    }[];
    subBudgetType: {
      name: string;
    };
  }

  interface Lead {
    id: number;
    name: string;
    requestAmount: string;
    requestBy: string;
    status: string;
    budget: Budget;
    requestDate: string;
    subBudgetType: {
      name: string;
    };
  }

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  const handleClear = () => {
    setYear("");
    setBudget("");
    setUploadedFile([]);
    setClearFileUploader(true);

    // Clear errors
    setFieldErrors({ year: "", budget: "", file: "" });
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    console.log("file error", fieldErrors.file);
  }, [fieldErrors.file]);

  const handleSubmit = () => {
    let isvalid = true;

    if (year === "") {
      handleErrors({ year: "Year is required" });
      isvalid = false;
    }

    if (budget === "") {
      handleErrors({ budget: "Budget is required" });
      isvalid = false;
    }

    if (budget === "0.00") {
      handleErrors({ budget: "Budget must be greater than 0" });
      isvalid = false;
    }

    if (uploadedFile.length === 0) {
      handleErrors({ file: "File is required" });
      isvalid = false;
    }

    if (
      fieldErrors.year === "" &&
      fieldErrors.budget === "" &&
      fieldErrors.file === "" &&
      isvalid
    ) {
      // Collect all form data
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        requestBudget: budget,
        year: year,
        requestedBy: employeeId,
      };

      formData.append("budgetDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want Request New Annual Budget?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Requesting budget...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createBudget(token, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Requested Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Request Budget");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  const handleDelete = (id: any) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const loadingToast = toast.loading("Deleting Sub-Budget...");
        apiDefinitions.deletePendingSubBudgets(token, id).then((response) => {
          if (response.data.code === 200) {
            toast.dismiss(loadingToast);
            toast.success("Sub-Budget Deleted Successfully");
            setRefresh(!refresh);
          } else {
            toast.dismiss(loadingToast);
            toast.error("Failed to Delete Sub-Budget");
          }
        });
      }
    });
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date in YYYY-MM-DD format
  };

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Budget) => JSX.Element;
  }

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    const offset = currentPage - 1;

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getSubBudgetByStatus(token, id, 2, offset, itemsPerPage)
          .then((response: any) => {
            if (response.data.code === 200) {
              console.log("Sub Budget: ", response.data.data);
              setTotalPages(response.data.data.totalPages);
              setCurrentPage(response.data.data.pageable.pageNumber + 1);
              setLeads(response.data.data.content);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    refreshCampaigns();
  }, [itemsPerPage, currentPage, refresh]);

  useEffect(() => {
    const budgetRegex = /^\d+(\.\d{2})$/;
    if (budget === "") {
      if (initalError) {
        handleErrors({ budget: "Budget is required" });
      } else {
        setInitalError(true);
      }
    } else if (!budgetRegex.test(budget)) {
      handleErrors({
        budget: "Budget must be a number with exactly two decimal places",
      });
    } else {
      handleErrors({ budget: "" });
    }
  }, [budget]);

  useEffect(() => {
    if (year === "") {
      if (initalError) {
        handleErrors({ year: "Year is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ year: "" });
    }
  }, [year]);

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  useEffect(() => {
    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleClear);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleClear);
      }
    };
  }, []);

  const handleSearch = () => {
    setSearchClicked(!searchClicked);
  };

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleClose = () => {
    setSearchClicked(!searchClicked);
    setSearchKey("");
    setLeadPriority("");
    setLeadStatus("");
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Annual Sub-Budget</h4>
                  </div>
                  <div className="col-8 text-end">
                    <Link
                      to="/budget/approvedBudgets"
                      className="btn btn-primary"
                    >
                      <i
                        className="feather-arrow-left"
                        style={{ fontSize: "17px", marginRight: "5px" }}
                      />
                      Back to list
                    </Link>
                  </div>
                </div>
              </div>
              <div className="campaign-tab">
                <ul className="nav">
                  <li>
                    <Link
                      to={`/budget/subBudgets/${id}`}
                      className={
                        isLinkActive(`/budget/subBudgets/${id}`) ? "active" : ""
                      }
                    >
                      Approved Sub-Budgets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/budget/subBudgets/requestedSubBudget/${id}`}
                      className={
                        isLinkActive(
                          `/budget/subBudgets/requestedSubBudget/${id}`
                        )
                          ? "active"
                          : ""
                      }
                    >
                      Requested Sub-Budgets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/budget/subBudgets/rejectedSubBudget/${id}`}
                      className={
                        isLinkActive(
                          `/budget/subBudgets/rejectedSubBudget/${id}`
                        )
                          ? "active"
                          : ""
                      }
                    >
                      Rejected Sub-Budgets
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Campaign Tab */}

              <div className="col-md-12">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-header">
                      {/* <div className="card-title">View All Leads 2</div> */}
                      <div className="row">
                        <div className="col-md-1 mt-3">
                          <Form.Select
                            value={itemsPerPage}
                            onChange={(event) =>
                              setItemsPerPage(parseInt(event.target.value, 10))
                            }
                            aria-label="Select number of items per page"
                            className="w-auto"
                          >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table text-nowrap table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sub-Budget Name</th>
                              <th scope="col">Budget Year</th>
                              <th scope="col">Sub-Budget Type</th>
                              <th scope="col">Requested Amount (LKR)</th>
                              <th scope="col">Requested Date & Time</th>

                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leads.length > 0 ? (
                              leads.map((lead, index) => (
                                <tr key={index}>
                                  <td>{lead.name}</td>
                                  <td>{lead.budget.year}</td>
                                  <td>{lead.subBudgetType.name}</td>
                                  <td>{lead.requestAmount}</td>
                                  <td>
                                    {lead.requestDate
                                      ? lead.requestDate
                                          .split(" ")
                                          .slice(1, 4)
                                          .join(" ") // Extract "Jan 22 2025"
                                      : "N/A"}
                                  </td>
                                  <td>
                                    <div className="hstack gap-2 fs-15">
                                      <Link
                                        to={`${route.viewSubBudget.replace(
                                          ":id",
                                          lead.id.toString()
                                        )}`}
                                        className="btn btn-icon btn-md btn-success"
                                      >
                                        <i
                                          className="feather-eye"
                                          style={{ fontSize: "17px" }}
                                        />
                                      </Link>
                                      <button
                                        onClick={() => handleDelete(lead.id)}
                                        className="btn btn-icon btn-md btn-danger"
                                      >
                                        <i
                                          className="feather-trash"
                                          style={{ fontSize: "17px" }}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={9} className="text-center">
                                  No Data Available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <Pagination className="mt-3 justify-content-center">
                        <Pagination.Prev
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        />
                        {Array.from({ length: totalPages }, (_, index) => (
                          <Pagination.Item
                            key={index + 1}
                            active={index + 1 === currentPage}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        ))}
                        <Pagination.Next
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        />
                      </Pagination>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default RequestedSubBudget;
