import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../core/common/dataTable/index";
import CampaignModal from "../../crm/campaign/campaign_modal";
import apiDefinitions from "../../../api/apiDefinitions";
import { contactData } from "../../../core/data/json/contactData";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toast } from "react-hot-toast";

import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";

import { useNavigate } from "react-router-dom";

const CampaignComplete = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [allCampaignCount, setAllCampaignCount] = useState("");
  const [activeCount, setActiveCount] = useState("");
  const [inactiveCount, setInactiveCount] = useState("");
  const [completedCount, setCompletedCount] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  );
  const hasDataLoaded = useRef(false);
  const [searchKey, setSearchKey] = useState("");
  const [campaignTypeId, setCampaignTypeId] = useState("");
  const [status, setStatus] = useState("inactive");
  const [selectedCategory, setSelectedCategory] = useState("");
  interface Category {
    id: string;
    name: string;
  }

  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [searchClicked, setSearchClicked] = useState(false);

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const refreshWindow = () => {
    window.location.reload();
  };

  const renderNamePopover = (name: string) => (
    <Popover id="popover-basic">
      <Popover.Body>{name}</Popover.Body>
    </Popover>
  );

  interface Campaign {
    id: string;
    name: string;
    targetAudience: string;
    leadCount: number;
    status: string;
    startDate: string;
    endDate: string;
  }

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Campaign) => JSX.Element;
  }

  const columns: Column[] = [
    {
      title: "Name",
      dataIndex: "name",
      render: (name: string) => (
        <td>
          {name.length > 30 ? (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={renderNamePopover(name)}
            >
              <span
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {name.substring(0, 30)}...
              </span>
            </OverlayTrigger>
          ) : (
            name
          )}
        </td>
      ),
    },
    {
      title: "Target Audience",
      dataIndex: "targetAudience",
      render: (targetAudience: { id: number; name: string }[]) => {
        const popover = (
          <Popover id="target-audience-popover">
            <Popover.Body>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {targetAudience.map((audience, index) => (
                  <li key={index}>{audience.name}</li>
                ))}
              </ul>
            </Popover.Body>
          </Popover>
        );

        return (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={popover}
          >
            <button className="btn btn-outline-success btn-wave" type="button">
              View Audience
            </button>
          </OverlayTrigger>
        );
      },
    },
    {
      title: "Est. Lead Count",
      dataIndex: "leadCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <div>
          {status === "active" && (
            <span className="badge badge-pill badge-status bg-success">
              {status}
            </span>
          )}
          {status === "inactive" && (
            <span className="badge badge-pill badge-status bg-warning">
              {status}
            </span>
          )}
          {status === "completed" && (
            <span className="badge badge-pill badge-status bg-info">
              {status}
            </span>
          )}
          {status === "expired" && (
            <span className="badge badge-pill badge-status bg-danger">
              {status}
            </span>
          )}
          {status === "inCompleted" && (
            <span className="badge badge-pill badge-status bg-secondary">
              {status}
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "approveStatus",
      render: (approveStatus: number[]) => (
        <div>
          {approveStatus.includes(1) && (
            <span className="badge badge-pill badge-status bg-success">
              Approved
            </span>
          )}
          {approveStatus.includes(2) && (
            <span className="badge badge-pill badge-status bg-warning">
              Pending
            </span>
          )}
          {approveStatus.includes(3) && (
            <span className="badge badge-pill badge-status bg-danger">
              Rejected
            </span>
          )}
          {approveStatus.includes(4) && (
            <span className="badge badge-pill badge-status bg-info">
              Expired
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record: Campaign) => (
        <div className="dropdown table-action">
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              to={`${route.updateCampaign.replace(
                ":id",
                record.id.toString()
              )}`}
            >
              <i className="ti ti-edit text-blue"></i> Edit Campaign
            </Link>

            <Link
              to={`${route.viewCampaign
                .replace(":id", record.id.toString())
                .replace(":status", "false")}`}
            >
              <i className="ti ti-eye text-blue"></i> View Campaign
            </Link>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    console.log("Campaign ID: ", selectedCampaignId);
  }, [selectedCampaignId]);

  // const refreshCampaigns = () => {
  //   const token = sessionStorage.getItem("token");

  //   if (!token) {
  //     console.error("No token found. Please log in again.");
  //     navigate(route.login);
  //     return;
  //   }

  //   try {
  //     const decodedToken: DecodedToken = jwtDecode(token);
  //     const currentTime = Math.floor(Date.now() / 1000);

  //     if (decodedToken.exp && decodedToken.exp > currentTime) {
  //       apiDefinitions
  //         .getAllCampaign(token, searchKey, campaignTypeId, status)
  //         .then((response: any) => {
  //           console.log("Campaign Categories: ", response.data.data);
  //           setCampaignList(response.data.data);
  //         });
  //     } else {
  //       console.log("Session expired. Please log in again.");
  //       navigate(route.login);
  //     }
  //   } catch (error) {
  //     console.error("Invalid token or unable to decode", error);
  //     navigate(route.login);
  //   }
  // };

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getAllCampaign(token, searchKey, campaignTypeId, status)
          .then((response: any) => {
            if (response.data.code === 200) {
              console.log("Campaign Categories: ", response.data.data);
              setCampaignList(response.data.data);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    refreshCampaigns();
  }, [searchClicked, status]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCampaignCount(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setAllCampaignCount(response.data.data.total);
          } else {
            console.error("Failed to fetch campaign categories");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAactiveInactiveCount(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setActiveCount(response.data.data.active);
            setInactiveCount(response.data.data.inactive);
          } else {
            console.error("Failed to fetch campaign categories");
            // Handle other response codes if necessary
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCompletedCampaigns(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setCompletedCount(response.data.data.completed);
          } else {
            console.error("Failed to fetch complete count");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCampaignTypes(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setCategoryList(response.data.data);
          } else {
            console.error("Failed to fetch campaign categories");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  const handleClearFilters = () => {
    setSearchKey("");
    setCampaignTypeId("");
    setSearchClicked(!searchClicked);
  };

  const handleSearch = () => {
    setSearchClicked(!searchClicked);
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Campaign</h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              {/* Campaign Status */}
              <div className="row">
                <div className="col-xl-3 col-lg-6">
                  <div className="campaign-box bg-danger-light">
                    <div className="campaign-img">
                      <span>
                        <i className="ti ti-brand-campaignmonitor" />
                      </span>
                      <p>All Campaigns</p>
                    </div>
                    <h2>{allCampaignCount}</h2>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="campaign-box bg-warning-light">
                    <div className="campaign-img">
                      <span>
                        <i className="ti ti-send" />
                      </span>
                      <p>Active Campaigns</p>
                    </div>
                    <h2>{activeCount}</h2>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="campaign-box bg-purple-light">
                    <div className="campaign-img">
                      <span>
                        <i className="ti ti-brand-feedly" />
                      </span>
                      <p>Inactive Campaigns</p>
                    </div>
                    <h2>{inactiveCount}</h2>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="campaign-box bg-success-light">
                    <div className="campaign-img">
                      <span>
                        <i className="ti ti-brand-pocket" />
                      </span>
                      <p>Completed</p>
                    </div>
                    <h2>{completedCount}</h2>
                  </div>
                </div>
              </div>
              {/* /Campaign Status */}
              {/* Campaign Tab */}
              <div className="campaign-tab">
                <ul className="nav">
                  <li>
                    <Link
                      to={route.campaign}
                      className={isLinkActive(route.campaign) ? "active" : ""}
                    >
                      Active Campaigns
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.campaignComplete}
                      className={
                        isLinkActive(route.campaignComplete) ? "active" : ""
                      }
                    >
                      Inactive Campaigns
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.campaignAll}
                      className={
                        isLinkActive(route.campaignAll) ? "active" : ""
                      }
                    >
                      All Campaigns
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Campaign Tab */}
              <div className="card">
                <div className="card-header">
                  {/* Search */}
                  <div className="row align-items-center">
                    <div className="col-sm-3">
                      <div className="icon-form mb-3 mb-sm-0">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Campaign"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <select
                        className={`form-select `}
                        id="validationCustomContactMethod"
                        value={campaignTypeId}
                        onChange={(e) => setCampaignTypeId(e.target.value)}
                        required
                      >
                        <option value="">Filter By Campaign Type</option>
                        {categoryList.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-2">
                      {!searchClicked ? (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => handleClearFilters()}
                        >
                          Clear Filters
                        </button>
                      )}
                    </div>
                    <div className="col-sm-3">
                      <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                        <Link
                          to="#"
                          className="btn btn-primary"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add"
                        >
                          <i className="ti ti-square-rounded-plus me-2" />
                          Add New Campaign
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        {/* <div className="card-header justify-content-between">
                            <div className="card-title">Always responsive</div>
                          </div> */}
                        <div className="card-body">
                          <div className="table-responsive custom-table ">
                            <Table
                              dataSource={campaignList}
                              columns={columns}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <CampaignModal
        refreshCampaigns={refreshCampaigns}
        refreshWindow={refreshWindow}
      />
    </>
  );
};

export default CampaignComplete;
