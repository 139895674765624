import React, { useEffect, useState, useRef } from "react";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { Link } from "react-router-dom";
import { Pagination, Form, OverlayTrigger, Popover } from "react-bootstrap";
import {
  priority,
  status,
} from "../../../core/common/selectoption/selectoption";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AssignLeads = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const hasDataLoaded = useRef(false);
  const [isStatusValid, setIsStatusValid] = useState(false);

  const [employeeId, setEmployeeId] = useState("");
  const [leads, setLeads] = useState<Lead[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");
  const [leadPriority, setLeadPriority] = useState("");
  const [leadId, setLeadId] = useState<number | null>(null);
  const [fullName, setFullName] = useState("");
  const [assignedBy, setAssignedBy] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [description, setDescription] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [isNoteValid, setIsNoteValid] = useState(false);
  const [followUpDate, setFollowUpDate] = useState<Date | null>(null);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Lead {
    id: number;
    fullname: string;
    contactNumber: number;
    email: number;
    nic: string;
    followUpDate: string;
    leadPriorityLevelId: number;
    leadStatus: {
      status_name: string;
      color: string;
    };
    searchKey: string;
    searchClicked: boolean;
    leadPriority: string;
    assignStatus: number;
  }

  interface FetchLeadsResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  interface FetchLeadsStatusResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  interface FetchLeadsPriorityResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Please log in again.");
        navigate(route.login); // Redirect to login if no token
        return;
      }

      try {
        const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp <= currentTime) {
          console.log("Session expired. Please log in again.");
          navigate(route.login); // Redirect to login if token expired
          return;
        }

        if (!hasDataLoaded.current) {
          hasDataLoaded.current = true;
          return;
        }

        const offset = (currentPage - 1) * itemsPerPage;
        console.log("Fetching leads with offset", offset);

        const toastId = toast.loading("Loading Data...");
        try {
          const response = await apiDefinitions.getAllPendingLeadsAndSearch(
            token,
            offset,
            itemsPerPage,
            searchKey,
            leadPriority,
            leadStatus,
            formatDate(followUpDate) || ""
          );

          if (response.data.code === 200) {
            toast.dismiss(toastId);
            toast.success("Data Loaded", { duration: 5000 });
            const data: FetchLeadsResponse = response.data.data;
            console.log("Fetched leads", data.content);
            setLeads(data.content);
            setTotalPages(data.totalPages);
          } else if (response.data.code === 404) {
            toast.dismiss(toastId);
            toast.error("No Leads Were Found");
          } else {
            toast.dismiss(toastId);
            toast.error("Failed to fetch leads. Please try again.");
          }
        } catch (error) {
          toast.dismiss(toastId);
          console.error("Failed to fetch leads", error);
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Invalid token or unable to decode", error);
        navigate(route.login); // Redirect if token is invalid
      }
    };

    fetchData();
  }, [
    currentPage,
    itemsPerPage,
    searchClicked,
    navigate,
    route.login,
    refresh,
  ]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = () => {
    setSearchClicked(!searchClicked);
  };

  const handleClose = () => {
    setSearchClicked(!searchClicked);
    setSearchKey("");
    setLeadPriority("");
    setLeadStatus("");
    setFollowUpDate(null);
  };

  const handleAssignLead = (leadId: number) => {
    setLeadId(leadId);
  };

  const handleCancel = () => {
    setLeadId(null);
    setDescription("");
    setApprovalStatus("");
    setAssignedBy("");
    setAssignedTo("");
  };

  const handleSubmit = () => {
    let isValid = true;

    if (!description) {
      isValid = false;
      setIsNoteValid(true);
    } else {
      setIsNoteValid(false);
    }

    if (!approvalStatus) {
      isValid = false;
      setIsStatusValid(true);
    } else {
      setIsStatusValid(false);
    }

    if (!isValid) {
      console.error("Form validation failed.");
      return; // Stop execution if validation fails
    }

    console.log("Submitted");
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    if (isValid) {
      Swal.fire({
        title: "Do You Want To Approve/ Reject Assignment?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            const loading = toast.loading("Processing lead assignment...");

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              apiDefinitions
                .leadAproval(
                  token,
                  leadId,
                  approvalStatus,
                  employeeId,
                  description
                )
                .then((response: any) => {
                  const data = response.data.data;
                  console.log("Lead Data By ID: ", data);

                  setFullName(data.fullname);
                  setAssignedBy(data.assignedBy);
                  setAssignedTo(data.assignedTo);

                  // Close the modal
                  // const modalElement = document.getElementById("add_faq");
                  // if (modalElement) {
                  //   modalElement.classList.remove("show");
                  //   modalElement.style.display = "none";
                  //   const backdrop = document.querySelector(".modal-backdrop");
                  //   if (backdrop) {
                  //     backdrop.remove();
                  //   }
                  // }
                  window.location.reload();

                  // Show success Swal
                  Swal.fire({
                    icon: "success",
                    title: "Assignment Completed Successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });

                  toast.dismiss(loading);

                  setDescription("");
                  setApprovalStatus("");
                  setRefresh(!refresh);
                })
                .catch((error) => {
                  console.error("Error approving lead:", error);
                  toast.dismiss(loading);
                  Swal.fire({
                    icon: "error",
                    title: "Failed to Save Changes",
                    text: "An error occurred while saving changes. Please try again.",
                  });
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate("/login");
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          // Show toast for not saving
          toast("Approval Was Incomplete", {
            icon: "⚠️",
          });
        }
      });
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    if (leadId) {
      try {
        const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp > currentTime) {
          apiDefinitions.getLeadById(token, leadId).then((response: any) => {
            const data = response.data.data;
            console.log("Lead Data By ID: ", data);

            setFullName(data.fullname);
            setAssignedBy(data.assignedBy);
            setAssignedTo(data.assignedTo);
          });
        } else {
          console.log("Session expired. Please log in again.");
          navigate("/login");
        }
      } catch (error) {
        console.error("Invalid token or unable to decode", error);
      }
    }
  }, [leadId, navigate]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getMarketingEmployeeList(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setEmployeeList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  interface ReasonPopoverProps {
    reason: string;
  }

  const renderReasonPopover = ({ reason }: ReasonPopoverProps): JSX.Element => (
    <Popover id="popover-reason">
      <Popover.Body>{reason}</Popover.Body>
    </Popover>
  );

  useEffect(() => {
    console.log("Selected Date:", followUpDate);
  }, [followUpDate]);

  const formatDate = (date: Date | null): string => {
    if (!date) return "";
    const newDate = new Date(
      date.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    ); // Add 5 hours and 30 minutes
    return newDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    console.log("Selected Date:", formatDate(followUpDate));
  }, [followUpDate]);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Lead Assignment</div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <Form.Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        aria-label="Select number of items per page"
                        className="w-auto"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>

                      <div className="col-md-3 mb-3 mt-3">
                        <select
                          className={`form-select`}
                          id="validationCustomContactMethod"
                          value={leadStatus}
                          onChange={(e) => setLeadStatus(e.target.value)}
                          required
                        >
                          <option>Select a Lead Status</option>
                          <option value="1">New</option>
                          <option value="2">Contacted</option>
                          <option value="3">Interested</option>
                          <option value="4">Application in Progress</option>
                          <option value="5">Enrolled</option>
                          <option value="6">Disqualified</option>
                          {/* <option value="7">Follow-Up Needed</option> */}
                          <option value="8">Lost</option>
                        </select>
                      </div>

                      <div className="col-md-3">
                        <select
                          className={`form-select`}
                          id="validationCustomContactMethod"
                          value={leadPriority}
                          onChange={(e) => setLeadPriority(e.target.value)}
                          required
                        >
                          <option>Select a Priority Level</option>
                          <option value="1">Low </option>
                          <option value="2">Medium</option>
                          <option value="3">High </option>
                        </select>
                      </div>

                      <div className="col-md-2">
                        <DatePicker
                          onChange={(date) => setFollowUpDate(date)}
                          className="form-control"
                          placeholderText="Filter By Follow-Up Date"
                          selected={followUpDate}
                        />
                      </div>

                      <div className="col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder="Search"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-1">
                        <button
                          className="btn btn-primary"
                          onClick={searchClicked ? handleClose : handleSearch}
                        >
                          <i
                            className={`fas ${
                              searchClicked ? "fa-times" : "fa-filter"
                            }`}
                            style={{
                              marginRight: "5px",
                              padding: "4px",
                              fontSize: "15px",
                              marginLeft: "1px",
                            }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table text-nowrap table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Full Name</th>
                            <th scope="col">NIC</th>
                            <th scope="col">Contact Number</th>
                            <th scope="col">Email</th>
                            <th scope="col">Follow Up Date</th>
                            <th scope="col">Assign Status</th>
                            <th scope="col">Lead Status</th>
                            <th scope="col">Lead Priority</th>

                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {leads.map((lead, index) => (
                            <tr key={index}>
                              <td>
                                {lead.fullname.length > 30 ? (
                                  <OverlayTrigger
                                    trigger={["hover", "focus"]}
                                    placement="top"
                                    overlay={renderReasonPopover({
                                      reason: lead.fullname,
                                    })}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      {lead.fullname.substring(0, 30)}...
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  lead.fullname
                                )}
                              </td>
                              <td>{lead.nic}</td>
                              <td>{lead.contactNumber}</td>
                              <td>{lead.email}</td>
                              <td>
                                {lead.followUpDate
                                  ? new Date(lead.followUpDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : "N/A"}
                              </td>
                              <td>
                                {Array.isArray(lead.assignStatus) &&
                                lead.assignStatus.length > 0 ? (
                                  lead.assignStatus.map((status, idx) => (
                                    <span
                                      key={idx}
                                      className={`badge bg-soft-${
                                        status === 1
                                          ? "success"
                                          : status === 2
                                          ? "warning"
                                          : status === 3
                                          ? "info"
                                          : status === 4
                                          ? "danger"
                                          : "secondary"
                                      } me-1`}
                                    >
                                      {status === 1
                                        ? "Approved"
                                        : status === 2
                                        ? "Pending"
                                        : status === 3
                                        ? "Assigned"
                                        : status === 4
                                        ? "Rejected"
                                        : "N/A"}
                                    </span>
                                  ))
                                ) : (
                                  <span className="badge bg-soft-secondary">
                                    N/A
                                  </span>
                                )}
                              </td>

                              <td>
                                <span
                                  className={`badge bg-soft-${lead.leadStatus.color}`}
                                >
                                  {lead.leadStatus.status_name}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={`badge bg-soft-${
                                    lead.leadPriorityLevelId === 1
                                      ? "secondary"
                                      : lead.leadPriorityLevelId === 2
                                      ? "success"
                                      : lead.leadPriorityLevelId === 3
                                      ? "danger"
                                      : "secondary"
                                  }`}
                                >
                                  {lead.leadPriorityLevelId === 1
                                    ? "Low"
                                    : lead.leadPriorityLevelId === 2
                                    ? "Medium"
                                    : lead.leadPriorityLevelId === 3
                                    ? "High"
                                    : "N/A"}
                                </span>
                              </td>
                              <td>
                                <div className="hstack gap-2 fs-15">
                                  <Link
                                    to="#"
                                    className="btn btn-info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_faq"
                                    onClick={() => {
                                      handleAssignLead(lead.id);
                                    }}
                                  >
                                    <i
                                      className="feather-check-circle"
                                      style={{ fontSize: "17px" }}
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination className="mt-3 justify-content-center">
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="add_faq" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Lead Assign Approval</h5>
              <div className="d-flex align-items-center mod-toggle">
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  <i className="ti ti-x" />
                </button>
              </div>
            </div>
            <form>
              <div className="modal-body">
                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Assigned By
                  </label>
                  <select
                    className={`form-select `}
                    id="validationCustomContactMethod"
                    value={assignedBy}
                    onChange={(e) => setAssignedBy(e.target.value)}
                    required
                    disabled
                  >
                    <option value="">Select an option</option>
                    {employeeList.map((employeee) => (
                      <option
                        key={employeee.serial_number}
                        value={employeee.serial_number}
                      >
                        {employeee.name_in_full}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Assigned To
                  </label>
                  <select
                    className={`form-select`}
                    id="validationCustomContactMethod"
                    value={assignedTo}
                    onChange={(e) => setAssignedTo(e.target.value)}
                    required
                    disabled
                  >
                    <option value="">Select an option</option>
                    {employeeList.map((employeee) => (
                      <option
                        key={employeee.serial_number}
                        value={employeee.serial_number}
                      >
                        {employeee.name_in_full}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Approval Note
                  </label>
                  <textarea
                    rows={1}
                    cols={5}
                    className={`form-control ${
                      isNoteValid ? "is-invalid" : ""
                    }`}
                    placeholder="Enter text here"
                    defaultValue={""}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {isNoteValid && (
                    <div className="invalid-feedback">
                      Please enter a description.
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Lead Approval Status
                  </label>
                  <select
                    className={`form-select ${
                      isStatusValid ? "is-invalid" : ""
                    }`}
                    id="validationCustomContactMethod"
                    value={approvalStatus}
                    onChange={(e) => setApprovalStatus(e.target.value)}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="approved">Approve </option>
                    <option value="rejected">Reject</option>
                  </select>
                  {isStatusValid && (
                    <div className="invalid-feedback">
                      Please select an approval status.
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <div className="d-flex align-items-center justify-content-end m-0">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    Cancel
                  </Link>
                  <button
                    type="button"
                    // data-bs-dismiss="modal"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignLeads;
