import React, { useState, useRef, useEffect } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import Dropdown from "./yearDropDown";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import apiDefinitions from "../../../api/apiDefinitions";
import ApexCharts from "apexcharts";

const currentYear = new Date().getFullYear();
const defultMonth = 30;
const route = all_routes;

const LeadsDashboard = () => {
  const chartRef = useRef(null);
  const navigate = useNavigate();

  const [selectedYear, setSelectedYear] = useState<number | null>(currentYear);
  const [selectedSourceMonth, setSelectedSourceMonth] = useState<number | null>(
    defultMonth
  );
  const [selectedLeadMonth, setSelectedLeadMonth] = useState<number | null>(
    defultMonth
  );
  const [selectedCourseMonth, setSelectedCourseMonth] = useState<number | null>(
    defultMonth
  );
  const [selectedSocialMediaMonth, setSelectedSocialMediaMonth] = useState<
    number | null
  >(defultMonth);
  const [leadCount, setLeadCount] = useState<number>(0);
  const [leadCountToday, setLeadCountToday] = useState<number>(0);
  const [incompleteLeadCount, setIncompleteLeadCount] = useState<number>(0);
  const [enrolledLeadCount, setEnrolledLeadCount] = useState<number>(0);

  const [statusData, setStatusData] = useState<any>([]);
  const [topCoursers, setTopCoursers] = useState<any>([]);

  const hasMounted = useRef(false);
  const hasSourceOfInquiryMounted = useRef(false);
  const hasLeadStatusMounted = useRef(false);
  const hasSocialMediaMounted = useRef(false);
  const hasTopCoursersMounted = useRef(false);
  const hasLeadDataMounted = useRef(false);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  useEffect(() => {
    if (chartRef.current) {
      let maxStatusValue = 80;
      if (statusData.length !== 0) {
        maxStatusValue = Math.max(
          statusData.New,
          statusData.Contacted,
          statusData.Interested,
          statusData.Application_in_Progress,
          statusData.Enrolled,
          statusData.Disqualified,
          statusData.Follow_Up_Needed,
          statusData.Lost
        );
      }

      const options = {
        series: [
          {
            name: "Leads",
            colors: ["#FFC38F"],
            data: [
              {
                x: "New",
                y: statusData?.New || 0,
              },
              {
                x: "Contacted",
                y: statusData?.Contacted || 0,
              },
              {
                x: "Interested",
                y: statusData?.Interested || 0,
              },
              {
                x: "In-progress",
                y: statusData?.Application_in_Progress || 0,
              },
              {
                x: "Enrolled",
                y: statusData?.Enrolled || 0,
              },
              {
                x: "Disqualified",
                y: statusData?.Disqualified || 0,
              },
              {
                x: "Follow-up",
                y: statusData?.Follow_Up_Needed || 0,
              },
              {
                x: "Lost",
                y: statusData?.Lost || 0,
              },
            ],
          },
        ],
        chart: {
          type: "bar",
          height: 275,
        },
        plotOptions: {
          bar: {
            borderRadiusApplication: "around",
            columnWidth: "40%",
          },
        },
        colors: ["#00918E"],
        xaxis: {
          type: "category",
          group: {
            style: {
              fontSize: "7px",
              fontWeight: 700,
            },
          },
        },
        yaxis: {
          min: 0,
          max: maxStatusValue + 5,
          tickAmount: 5,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup on unmount
      return () => {
        chart.destroy();
      };
    }
  }, [statusData]);

  const handleSelectYear = (year: number) => {
    setSelectedYear(year);
    console.log("Selected year:", year); // You can use the selected year value as needed
  };

  //Get lead count by year
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedYear) {
          apiDefinitions
            .getLeadCountByYear(token, selectedYear)
            .then((response: any) => {
              if (response.data.code === 200) {
                console.log("Categories Sub Types: ", response.data.data);

                const apiData = response.data.data;

                const dataArray = Object.values(apiData); // Convert response object to array

                const maxDataValue = Math.max(...(dataArray as number[]));

                setChartOptions4((prevOptions: any) => ({
                  ...prevOptions,
                  series: [
                    {
                      name: "Reports",
                      data: dataArray,
                    },
                  ],
                  yaxis: {
                    ...prevOptions.yaxis,
                    max: maxDataValue + 5, // Adjust max dynamically
                  },
                }));
              }
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedYear]);

  //Get lead percentage by source
  useEffect(() => {
    if (!hasSourceOfInquiryMounted.current) {
      hasSourceOfInquiryMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedSourceMonth) {
          apiDefinitions
            .getLeadPercentageBySource(token, selectedSourceMonth)
            .then((response: any) => {
              if (response.data.code === 200) {
                const apiData = response.data.data;

                const labelsArray = Object.keys(apiData); // Extract keys as labels
                const dataArray = Object.values(apiData); // Extract values as data

                console.log("Labels Array: ", labelsArray);
                console.log("Data Array: ", dataArray);

                setChartOptions3((prevOptions: any) => {
                  const updatedOptions = {
                    ...prevOptions,
                    series: dataArray, // Update series with dynamic data
                    options: {
                      ...prevOptions.options,
                      labels: labelsArray, // Update labels with dynamic data
                    },
                  };
                  console.log("Updated Chart Options: ", updatedOptions);
                  return updatedOptions;
                });
              }
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedSourceMonth]);

  //Get Lead status
  useEffect(() => {
    if (!hasLeadStatusMounted.current) {
      hasLeadStatusMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedLeadMonth) {
          apiDefinitions
            .getLeadStatusCount(token, selectedLeadMonth)
            .then((response: any) => {
              if (response.data.code === 200) {
                const apiData = response.data.data;

                setStatusData(apiData);
              }
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedLeadMonth, navigate]);

  //Get Social Media Leads
  useEffect(() => {
    if (!hasSocialMediaMounted.current) {
      hasSocialMediaMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedSocialMediaMonth) {
          apiDefinitions
            .getLeadsBySocialMedia(token, selectedSocialMediaMonth)
            .then((response: any) => {
              if (response.data.code === 200) {
                const apiData = response.data.data;

                setChartOptions((prevOptions: any) => {
                  const updatedOptions = {
                    ...prevOptions,
                    series: [
                      apiData.Facebook,
                      apiData.Instagram,
                      apiData.LinkedIn,
                      apiData.Tik_Tok,
                      apiData.You_Tube,
                    ],
                  };
                  console.log("Updated Chart Options: ", updatedOptions);
                  return updatedOptions;
                });
              }
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedSocialMediaMonth, navigate]);

  //Get Top Courses
  useEffect(() => {
    if (!hasTopCoursersMounted.current) {
      hasTopCoursersMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedCourseMonth) {
          apiDefinitions
            .getTopFiveCourses(token, selectedCourseMonth)
            .then((response: any) => {
              if (response.data.code === 200) {
                const apiData = response.data.data;

                setTopCoursers(apiData);
              }
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedCourseMonth, navigate]);

  //Get lead count by year
  useEffect(() => {
    if (!hasLeadDataMounted.current) {
      hasLeadDataMounted.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getLeadCount(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;
            setLeadCount(apiData);
          }
        });

        apiDefinitions.getTodayLeadCount(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;
            setLeadCountToday(apiData);
          }
        });

        apiDefinitions.getIncompletedLeadCount(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;
            setIncompleteLeadCount(apiData);
          }
        });

        apiDefinitions.getEnrolledLeadCount(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;
            setEnrolledLeadCount(apiData);
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate]);

  const [chartOptions4, setChartOptions4] = useState<any>({
    series: [
      {
        name: "Reports",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    colors: ["#4A00E5"],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      min: 0,
      max: 60,
      tickAmount: 5,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  });

  const [chartOptions3, setChartOptions3] = useState<any>({
    series: [0, 0, 0, 0],
    options: {
      chart: {
        width: 400,
        height: 300,
        type: "pie",
      },
      legend: {
        position: "bottom",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 275,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [chartOptions, setChartOptions] = useState<any>({
    series: [0, 0, 0, 0, 0],
    chart: {
      width: 400,
      height: 300,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4A00E5", "#FFA201", "#0092E4", "#E41F07", "#800080"],
    labels: ["Facebook", "Instagram", "LinkedIn", "Tik Tok", "YouTube"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center ">
                  <div className="col-md-4">
                    <h3 className="page-title">Leads Dashboard</h3>
                  </div>
                  <div className="col-md-8 float-end ms-auto">
                    <div className="d-flex title-head">
                      <div className="daterange-picker d-flex align-items-center justify-content-center">
                        <div className="head-icons mb-0">
                          <CollapseHeader />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <div className="campaign-box bg-purple-light">
                <div className="campaign-img">
                  <span>
                    <i className="ti ti-user" />
                  </span>
                  <p>All Leads</p>
                </div>
                <h2>{leadCount}</h2>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="campaign-box bg-warning-light">
                <div className="campaign-img">
                  <span>
                    <i className="ti ti-user-search" />
                  </span>
                  <p>Leads Today</p>
                </div>
                <h2>{leadCountToday}</h2>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="campaign-box bg-danger-light">
                <div className="campaign-img">
                  <span>
                    <i className="ti ti-alert-circle" />
                  </span>
                  <p>Incomplete Leads</p>
                </div>
                <h2>{incompleteLeadCount}</h2>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6">
              <div className="campaign-box bg-success-light">
                <div className="campaign-img">
                  <span>
                    <i className="ti ti-user-plus" />
                  </span>
                  <p>Enrolled Leads</p>
                </div>
                <h2>{enrolledLeadCount}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="card w-100">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1" />
                      Leads By Months
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <Dropdown onSelectYear={handleSelectYear} />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="contact-report">
                    <Chart
                      options={chartOptions4}
                      series={chartOptions4.series}
                      type="area"
                      height={chartOptions4.chart.height}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 d-flex">
              <div className="card w-100">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1" />
                      Source of Inquiry
                    </h4>
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        Last {selectedSourceMonth} Days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedSourceMonth(30)}
                        >
                          Last 30 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedSourceMonth(15)}
                        >
                          Last 15 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedSourceMonth(7)}
                        >
                          Last 7 Days
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="leadpiechart">
                    <Chart
                      options={chartOptions3.options}
                      series={chartOptions3.series}
                      type={chartOptions3.options.chart.type}
                      width={chartOptions3.options.chart.width}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1" />
                      Lead Status
                    </h4>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown">
                        <Link
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          to="#"
                        >
                          Last {selectedLeadMonth} Days
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => setSelectedLeadMonth(30)}
                          >
                            Last 30 Days
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => setSelectedLeadMonth(15)}
                          >
                            Last 15 Days
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => setSelectedLeadMonth(7)}
                          >
                            Last 7 Days
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="deals-chart" ref={chartRef} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5 d-flex">
              <div className="card w-100">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1" />
                      Leads By Social Media
                    </h4>
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        <i className="ti ti-calendar-check me-2" />
                        Last {selectedSocialMediaMonth} days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedSocialMediaMonth(15)}
                        >
                          Last 15 days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedSocialMediaMonth(30)}
                        >
                          Last 30 days
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id="contacts-analysis">
                    <Chart
                      options={chartOptions}
                      series={chartOptions.series}
                      type={chartOptions.chart.type}
                      width={chartOptions.chart.width}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <div className="card flex-fill">
                <div className="card-header border-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                    <h4>
                      <i className="ti ti-grip-vertical me-1" />
                      Top Courses
                    </h4>
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        <i className="ti ti-calendar-check me-2" />
                        Last {selectedCourseMonth} days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedCourseMonth(15)}
                        >
                          Last 15 days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedCourseMonth(30)}
                        >
                          Last 30 days
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive custom-table">
                      <table className="table dataTable" id="deals-project">
                        <thead className="thead-light">
                          <tr>
                            <th>Course Name</th>
                            <th>Degree</th>
                            <th>Number of leads</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topCoursers
                            .slice(0, 5)
                            .map((course: any, index: number) => (
                              <tr
                                key={index}
                                className={index % 2 === 0 ? "even" : "odd"}
                              >
                                <td>{course.courseName}</td>
                                <td>{course.categoryName}</td>
                                <td>{course.leadCount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsDashboard;
