import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
const route = all_routes;

const Profile = () => {
  const navigate = useNavigate();
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }
  const [employeeId, setEmployeeId] = useState("");
  const [profilePic, setProfilePic] = useState("");
  interface Campaign {
    id: string;
    name: string;
    channel: string;
    allocatedBudget: string;
    startDate: string;
    endDate: string;
    status: string;
  }
  const [campaignList, setCampaignList] = useState<Campaign[]>([]);
  interface Employee {
    serial_number: string;
    name: string;
    [key: string]: any;
  }
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [updatedEmployeeList, setUpdatedEmployeeList] = useState<Employee[]>(
    []
  );
  interface EmployeeDetails {
    nameWithInitials: string;
    [key: string]: any;
  }
  const [employeeDetails, setEmployeeDetails] =
    useState<EmployeeDetails | null>(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.serial_number);
      // setEmployeeName(decodedToken.name);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getProfilePic(employeeId)
        .then((response: any) => {
          if (response.data.code === 200) {
            setEmployeeDetails(response.data.data);
            console.log("Employees List", response.data.data);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getMarketingList()
        .then((response: any) => {
          if (response.data.code === 200) {
            const fetchedEmployeeList = response.data.data;

            // Filter the list to exclude the currently logged-in user
            const filteredList = fetchedEmployeeList.filter(
              (employee: any) => employee.serial_number !== employeeId
            );

            // Update both states
            setEmployeeList(fetchedEmployeeList);
            setUpdatedEmployeeList(filteredList);

            console.log("Employee Details", fetchedEmployeeList);
            console.log("Filtered List", filteredList);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getAssignedCampaignInformation(token, decodedToken.serial_number)
          .then((response: any) => {
            if (response.data.code === 200) {
              // setCount(response.data.data);
              console.log("Assigned Campaign Information", response.data.data);
              setCampaignList(response.data.data);
            } else {
              // setCount(0);
              console.error("Failed to fetch assigned campaign information");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <h4 className="page-title">
                    {employeeDetails?.nameWithInitials}'s Profile
                  </h4>
                </div>
                <div className="col-sm-8 text-sm-end">
                  <div className="head-icons">
                    <CollapseHeader />
                  </div>
                </div>
              </div>
            </div>
            {/* /Page Header */}

            {/* /Settings Menu */}
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                {/* Settings Info */}
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="border-bottom mb-3 pb-3">
                        <h5 className="fw-semibold mb-1">
                          Employee Information
                        </h5>
                        {/* <p>Provide the information below</p> */}
                      </div>
                      <div className="mb-3">
                        <div className="profile-upload">
                          <div className="d-flex align-items-center mb-4">
                            <img
                              src={employeeDetails?.profilePictureUrl || ""}
                              alt="Profile"
                              style={{
                                width: "120px",
                                height: "120px",
                                borderRadius: "5%",
                                objectFit: "cover",
                              }}
                            />
                            <div className="ms-4">
                              <h5>{employeeDetails?.nameInFull}</h5>
                              <p className="text-muted">
                                {employeeDetails?.designation}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="border-bottom mb-3"> */}
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Name </label>
                            <input
                              type="text"
                              className="form-control"
                              value={employeeDetails?.name}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">NIC</label>
                            <input
                              type="text"
                              className="form-control"
                              value={employeeDetails?.nicNo}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">KIU E-mail</label>
                            <input
                              type="text"
                              className="form-control"
                              value={employeeDetails?.email}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <input
                              type="text"
                              className="form-control"
                              value={employeeDetails?.contactNo}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </form>
                  </div>
                </div>
                {/* /Settings Info */}
              </div>
            </div>
          </div>

          {/* <div className="col-md-12">
            <div className="card analytics-card">
              <div className="card-header d-flex justify-content-between align-items-center flex-wrap">
                <h5>
                  <i className="ti ti-grip-vertical" /> Assigned Campaign
                  Information
                </h5>
              </div>
              <div className="card-body">
                <div className="">
                  <div
                    id="analytic-contact_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6" />
                      <div className="col-sm-12 col-md-6" />
                    </div>
                    <div className="row dt-row">
                      <div className="col-sm-12 table-responsive">
                        <table
                          className="table table-striped no-footer"
                          id="analytic-contact"
                          // style={{ width: 635 }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "174.333px" }}
                              >
                                Campaign Name
                              </th>
                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 131 }}
                              >
                                Assigned Channel
                              </th>
                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 131 }}
                              >
                                Allocated Budget (LKR)
                              </th>

                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: 96 }}
                              >
                                Start Date
                              </th>
                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "152.333px" }}
                              >
                                End Date
                              </th>
                              <th
                                className="sorting_disabled"
                                rowSpan={1}
                                colSpan={1}
                                style={{ width: "152.333px" }}
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignList.map((campaign) => (
                              <tr key={campaign.id} className="odd">
                                <td>{campaign.name}</td>
                                <td>{campaign.channel}</td>
                                <td>{campaign.allocatedBudget}</td>
                                <td>{campaign.startDate}</td>
                                <td>{campaign.endDate}</td>
                                <td>
                                  <Tag color="green">ACTIVE</Tag>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-md-12">
            {/* /Settings Menu */}
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                {/* Settings Info */}
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="border-bottom mb-3 pb-3">
                        <h5 className="fw-semibold mb-1">
                          Fellow Marketing Members
                        </h5>
                        {/* <p>Please enter the address details</p> */}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          {updatedEmployeeList
                            .slice(0, Math.ceil(updatedEmployeeList.length / 2))
                            .map((employee) => (
                              <li
                                key={employee.serial_number}
                                className="list-group-item d-flex align-items-center"
                                style={{ marginBottom: "15px" }}
                              >
                                <img
                                  src={employee.profile_pic}
                                  alt="Profile"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "15%",
                                    marginRight: "10px",
                                    objectFit: "cover",
                                  }}
                                />
                                <span>{employee.name_in_full}</span>
                              </li>
                            ))}
                        </div>
                        <div className="col-md-6">
                          {updatedEmployeeList
                            .slice(Math.ceil(updatedEmployeeList.length / 2))
                            .map((employee) => (
                              <li
                                key={employee.serial_number}
                                className="list-group-item d-flex align-items-center"
                                style={{ marginBottom: "15px" }}
                              >
                                <img
                                  src={employee.profile_pic}
                                  alt="Profile"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "15%",
                                    marginRight: "10px",
                                    objectFit: "cover",
                                  }}
                                />
                                <span>{employee.name_in_full}</span>
                              </li>
                            ))}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* /Settings Info */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
