import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CollapseHeader from "../../../core/common/collapse-header";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import toast from "react-hot-toast";

const CallDashBoard = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }
  const hasDataLoaded = useRef(false);
  const navigate = useNavigate();
  const route = all_routes;
  const [days, setDays] = useState(7);
  const [answeredDays, setAnsweredDays] = useState(7);

  const chartRef = useRef(null);
  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");
  }, []);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
    console.log("Selected Year:", year);
    // You can perform any action here, such as fetching data based on the selected year
  };

  useEffect(() => {
    if (chartRef.current) {
      const options = {
        series: [
          {
            name: "sales",
            colors: ["#FFC38F"],
            data: [
              {
                x: "Inpipeline",
                y: 400,
              },
              {
                x: "Follow Up",
                y: 130,
              },
              {
                x: "Schedule",
                y: 248,
              },
              {
                x: "Conversation",
                y: 470,
              },
              {
                x: "Won",
                y: 470,
              },
              {
                x: "Lost",
                y: 180,
              },
            ],
          },
        ],
        chart: {
          type: "bar",
          height: 275,
        },
        plotOptions: {
          bar: {
            borderRadiusApplication: "around",
            columnWidth: "40%",
          },
        },
        colors: ["#00918E"],
        xaxis: {
          type: "category",
          group: {
            style: {
              fontSize: "7px",
              fontWeight: 700,
            },
          },
        },
        yaxis: {
          min: 0,
          max: 500,
          tickAmount: 5,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup on unmount
      return () => {
        chart.destroy();
      };
    }
  }, []);

  //  Leads By Stage
  const LeadsBySatge = useRef<({ chart?: ApexCharts } & HTMLDivElement) | null>(
    null
  );

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getNotAnsweredByNumber(token, days).then((response) => {
          if (response.data.code === 200) {
            console.log("Call Count: ", response.data.data);

            if (response.data && Array.isArray(response.data.data)) {
              const data: number[] = response.data.data.map(
                (item: { no_answer_count: number }) => item.no_answer_count
              );
              const categories: string[] = response.data.data.map(
                (item: { src: string }) => item.src
              );

              if (LeadsBySatge.current) {
                // Destroy previous chart before creating a new one
                if (LeadsBySatge.current.chart) {
                  LeadsBySatge.current.chart.destroy();
                }

                const options = {
                  series: [{ data: data }],
                  chart: {
                    type: "bar",
                    height: 150,
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  colors: ["#FC0027"],
                  xaxis: {
                    categories: categories,
                    min: 0,
                    max: Math.max(...data) + 15,
                    tickAmount: 7,
                  },
                };

                const chart = new ApexCharts(LeadsBySatge.current, options);
                chart.render();
                LeadsBySatge.current.chart = chart; // Store chart instance

                return () => {
                  chart.destroy();
                };
              }
            } else {
              console.error(
                "Invalid data structure in API response:",
                response.data
              );
            }
          } else {
            console.log("Failed to fetch data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  // Cleanup previous effect properly when `days` changes
  useEffect(() => {
    refreshCampaigns();
    return () => {
      if (LeadsBySatge.current?.chart) {
        LeadsBySatge.current.chart.destroy();
      }
    };
  }, [days]);

  // Won Deals Chat
  const wonChat = useRef<({ chart?: ApexCharts } & HTMLDivElement) | null>(
    null
  );

  const refreshWonChat = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getAnsweredByNumber(token, answeredDays)
          .then((response) => {
            if (response.data.code === 200) {
              console.log("Answered Calls Data: ", response.data.data);

              if (response.data && Array.isArray(response.data.data)) {
                // Map API response to data and categories
                const data: number[] = response.data.data.map(
                  (item: { answered_count: number }) => item.answered_count
                );
                const categories: string[] = response.data.data.map(
                  (item: { src: string }) => item.src
                );

                if (wonChat.current) {
                  // Destroy previous chart before creating a new one
                  if (wonChat.current.chart) {
                    wonChat.current.chart.destroy();
                  }

                  const options = {
                    series: [{ data: data }],
                    chart: {
                      type: "bar",
                      height: 150,
                    },
                    plotOptions: {
                      bar: {
                        horizontal: true,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    colors: ["#5CB85C"],
                    xaxis: {
                      categories: categories,
                      min: 0,
                      max: Math.max(...data) + 15, // Adjust max dynamically
                      tickAmount: 7,
                    },
                  };

                  const chart = new ApexCharts(wonChat.current, options);
                  chart.render();
                  wonChat.current.chart = chart; // Store chart instance

                  return () => {
                    chart.destroy();
                  };
                }
              } else {
                console.error(
                  "Invalid data structure in API response:",
                  response.data
                );
              }
            } else {
              console.log("Failed to fetch data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  // Run effect when `days` changes
  useEffect(() => {
    refreshWonChat();
    return () => {
      if (wonChat.current?.chart) {
        wonChat.current.chart.destroy();
      }
    };
  }, [answeredDays]);

  // Deals By Year
  const dealsByYear = useRef<({ chart?: ApexCharts } & HTMLDivElement) | null>(
    null
  );

  const fetchCallsByYear = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate("/login");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getCallsByYear(token, selectedYear).then((response) => {
          if (response.data.code === 200) {
            console.log("Call Data: ", response.data.data);

            if (response.data && Array.isArray(response.data.data)) {
              const callCounts = new Array(12).fill(0);
              response.data.data.forEach(
                (item: { call_month: number; call_count: number }) => {
                  if (item.call_month >= 1 && item.call_month <= 12) {
                    callCounts[item.call_month - 1] = item.call_count;
                  }
                }
              );

              if (dealsByYear.current) {
                if (dealsByYear.current.chart) {
                  dealsByYear.current.chart.destroy();
                }

                interface ChartOptions {
                  series: Array<{
                    name: string;
                    data: number[];
                  }>;
                  chart: {
                    height: number;
                    type: string;
                    zoom: {
                      enabled: boolean;
                    };
                  };
                  colors: string[];
                  dataLabels: {
                    enabled: boolean;
                  };
                  stroke: {
                    curve: string;
                  };
                  xaxis: {
                    categories: string[];
                  };
                  yaxis: {
                    labels: {
                      formatter: (val: number) => string;
                    };
                  };
                  legend: {
                    position: string;
                    horizontalAlign: string;
                  };
                }

                const options: ChartOptions = {
                  series: [
                    {
                      name: "Calls",
                      data: callCounts,
                    },
                  ],
                  chart: {
                    height: 273,
                    type: "area",
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: ["#E41F07"],
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    curve: "straight",
                  },
                  xaxis: {
                    categories: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                  },
                  yaxis: {
                    labels: {
                      formatter: (val) => `${val / 1000}K`,
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "left",
                  },
                };

                const chart = new ApexCharts(dealsByYear.current, options);
                chart.render();
                dealsByYear.current.chart = chart;
              }
            } else {
              console.error(
                "Invalid data structure in API response:",
                response.data
              );
            }
          } else {
            console.log("Failed to fetch data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate("/login");
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchCallsByYear();
  }, [selectedYear]);

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };
  const location = useLocation();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (location.pathname === "/leads/addQuickLeads") {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 2000);
    }
  }, [location.pathname]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center ">
                <div className="col-md-4">
                  <h3 className="page-title">Call Dashboard</h3>
                </div>
                <div className="col-md-8 float-end ms-auto">
                  <div className="d-flex title-head">
                    <div className="daterange-picker d-flex align-items-center justify-content-center">
                      <div className="head-icons mb-0">
                        <CollapseHeader />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Not Answered call By Number
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        Last {days} Days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setDays(7)}
                        >
                          Last 7 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setDays(15)}
                        >
                          Last 15 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setDays(30)}
                        >
                          Last 1 Month
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="last-chart" ref={LeadsBySatge} />
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="card flex-fill">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Answered call By Number
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        Last {answeredDays} Days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setAnsweredDays(7)}
                        >
                          Last 7 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setAnsweredDays(15)}
                        >
                          Last 15 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setAnsweredDays(30)}
                        >
                          Last 1 Month
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="won-chart" ref={wonChat} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Calls by Year {selectedYear}
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        {selectedYear}
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        {years.map((year) => (
                          <Link
                            key={year}
                            to="#"
                            className="dropdown-item"
                            onClick={() => handleYearChange(year)}
                          >
                            {year}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="deals-year" ref={dealsByYear} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallDashBoard;
