import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CollapseHeader from "../../core/common/collapse-header";
import { all_routes } from "../router/all_routes";
import { useNavigate, useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import FileUpload from "./approvedBudgets/fileUploader";
import toast from "react-hot-toast";
import apiDefinitions from "../../api/apiDefinitions";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import Swal from "sweetalert2";
import SingleFileUpload from "./approvedBudgets/singleFileUploader";
import { OverlayTrigger, Popover } from "react-bootstrap";

const route = all_routes;
const defultMonth = 30;

const ViewBudget = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [note, setNote] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<any>({
    note: "",
    budget: "",
    file: "",
  });
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);
  const [checkValidation, setCheckValidation] = useState(false);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [reload, setReload] = useState(false);
  const [topUpData, setTopUpData] = useState<any>([]);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [budgetData, setBudgetData] = useState<any>([]);
  const [diffData, setDiffData] = useState<string>("0");
  const [allocatedBudget, setAllocatedBudget] = useState<string>("0");
  const [initalError, setInitalError] = useState(false);

  const { id } = useParams();
  const budgetId = id;
  const hasImageLoaded = useRef(false);
  const hasTopUpListLoaded = useRef(false);
  const hasbudgetLoaded = useRef(false);

  const [subBudgetType, setSubBudgetType] = useState("");
  const [subBudgetTypeIsValid, setSubBudgetTypeIsValid] = useState(false);
  const [subBudgetList, setSubBudgetList] = useState<any>([]);
  const [subBudgetTransfer, setSubBudgetTransfer] = useState<any>([]);
  const [transferValue, setTransferValue] = useState("");
  const [transferFrom, setTransferFrom] = useState("");
  const [transferTo, setTransferTo] = useState("");
  const [transferValueIsValid, setTransferValueIsValid] = useState(false);
  const [transferFromIsValid, setTransferFromIsValid] = useState(false);
  const [transferToIsValid, setTransferToIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  const handleClear = () => {
    setFieldErrors({ note: "", budget: "", file: "" });

    console.log(fieldErrors);
    setBudget("");
    setNote("");
    setUploadedFile([]);
    setClearFileUploader(true);
  };

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  useState(defultMonth);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasImageLoaded.current) {
      hasImageLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getJustificationByBudgetID(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              setUploadAttendaceDocuments(response.data.data);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  interface SubBudget {
    id: number;
    name: string;
  }

  useEffect(() => {
    if (!hasTopUpListLoaded.current) {
      hasTopUpListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getTopUpBudgets(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;
              setTopUpData(apiData);
              // Do something with maxDataValue if needed
            } else {
              console.error("Failed to fetch data: ", response.data.message);
            }
          })
          .catch((error: any) => {
            console.error("API call failed: ", error);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (!hasbudgetLoaded.current) {
      hasbudgetLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    // if (budgetId) {
    //   return;
    // }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getBudgetByBudgetId(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              console.log("Campaign Data", response);
              const apiData = response.data.data;
              setBudgetData(apiData);

              apiDefinitions
                .getCampaignsByAnualBudget(token, apiData[0].year)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    const apiData1 = response.data.data;
                    setCampaignData(apiData1);

                    // Do something with maxDataValue if needed
                  } else {
                    console.error(
                      "Failed to fetch data: ",
                      response.data.message
                    );
                  }
                })
                .catch((error: any) => {
                  console.error("API call failed: ", error);
                });

              apiDefinitions
                .getSubBudgetTypeSummaryById(token, id)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    const apiData = response.data.data;

                    setChartOptionsSubBudgetType((prevOptions: any) => {
                      const updatedOptions = {
                        ...prevOptions,
                        series: apiData.map((item: any) => item.approvedAmount),
                        labels: apiData.map((item: any) => item.name),
                      };
                      console.log("Updated Chart Options: ", updatedOptions);
                      return updatedOptions;
                    });
                  } else {
                    console.error(
                      "Failed to fetch data: ",
                      response.data.message
                    );
                  }
                })
                .catch((error: any) => {
                  console.error("API call failed: ", error);
                });

              apiDefinitions
                .getBudgetDifference(token, apiData[0].year)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    const apiData = response.data.data;

                    setChartOptions((prevOptions: any) => {
                      const updatedOptions = {
                        ...prevOptions,
                        series: [
                          parseInt(apiData.difference, 10),
                          parseInt(apiData.allocatedBudgetSum, 10),
                        ],
                      };
                      console.log("Updated Chart Options: ", updatedOptions);
                      return updatedOptions;
                    });
                  } else {
                    console.error(
                      "Failed to fetch data: ",
                      response.data.message
                    );
                  }
                })
                .catch((error: any) => {
                  console.error("API call failed: ", error);
                });
            } else {
              console.error("Failed to fetch data: ", response.data.message);
            }
          })
          .catch((error: any) => {
            console.error("API call failed: ", error);
          });

        apiDefinitions
          .getSubBudgetSummaryById(token, id)
          .then((response: any) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;

              setChartOptionsSubBudget((prevOptions: any) => {
                const updatedOptions = {
                  ...prevOptions,
                  series: [
                    parseInt(apiData.remainingAmount, 10),
                    parseInt(apiData.totalRequestAmount, 10),
                  ],
                };
                console.log("Updated Chart Options: ", updatedOptions);
                return updatedOptions;
              });
            } else {
              console.error("Failed to fetch data: ", response.data.message);
            }
          })
          .catch((error: any) => {
            console.error("API call failed: ", error);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [budgetId]);

  useEffect(() => {
    if (uploadAttendaceDocuments !== null) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is null
      // You might want to set some default value or handle it based on your requirements
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments]);

  const [chartOptions, setChartOptions] = useState<any>({
    series: [0, 0],
    chart: {
      width: 400,
      height: 300,
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4CAF50", "#F44336"],
    labels: ["Remaining", "Spent"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [chartOptionsSubBudget, setChartOptionsSubBudget] = useState<any>({
    series: [0, 0],
    chart: {
      width: 400,
      height: 300,
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4CAF50", "#F44336"],
    labels: ["Remaining", "Spent"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [sline] = useState<any>({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    // colors: ['#4361ee'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Desktops",
        data: [100, 90, 80, 60, 49, 62, 69, 91, 148, 40, 51, 49],
      },
    ],

    grid: {
      row: {
        colors: ["#f1f2f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });

  const [chartOptionsSubBudgetType, setChartOptionsSubBudgetType] =
    useState<any>({
      series: [],
      chart: {
        width: 400,
        height: 300,
        type: "pie",
      },
      legend: {
        position: "bottom",
      },
      colors: ["#4CAF50", "#F44336", "#FFC107", "#9C27B0"],
      labels: [],
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

  useEffect(() => {
    const budgetRegex = /^\d+(\.\d{2})$/;
    if (budget === "") {
      if (initalError) {
        handleErrors({ budget: "Budget is required" });
      } else {
        setInitalError(true);
      }
    } else if (!budgetRegex.test(budget)) {
      handleErrors({
        budget: "Budget must be a number with exactly two decimal places",
      });
    } else {
      handleErrors({ budget: "" });
    }
  }, [budget]);

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (note === "") {
      if (initalError) {
        handleErrors({ note: "Note is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ note: "" });
    }
  }, [note]);

  useEffect(() => {
    console.log("errors", fieldErrors);
  }, [fieldErrors]);

  const handleDownloadJustification = (urls: any) => {
    if (Array.isArray(urls) && urls.length > 0) {
      urls.forEach((url: string) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").pop() || "justification";
        link.click();
      });
    } else {
      alert("No justification file available.");
    }
  };

  const handleSubmit = () => {
    let isvalid = true;

    if (budget === "0.00") {
      handleErrors({ budget: "Budget must be greater than 0" });
      isvalid = false;
    }

    if (budget === "") {
      handleErrors({ budget: "Budget is required" });
      isvalid = false;
    }

    if (note === "") {
      handleErrors({ note: "Note is required" });
      isvalid = false;
    }

    if (uploadedFile.length === 0) {
      handleErrors({ file: "File is required" });
      isvalid = false;
    }

    if (
      fieldErrors.note === "" &&
      fieldErrors.budget === "" &&
      fieldErrors.file === "" &&
      isvalid
    ) {
      // Collect all form data
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        topUpAmount: parseFloat(budget),
        reason: note,
        topUpBy: employeeId,
      };

      formData.append("topUpBudgetDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want Annual Budget Top Up ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Requesting budget...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .requestTopUpBudget(token, budgetId, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Top Up Requested Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Request Budget Budget Top Up");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  interface ReasonPopoverProps {
    reason: string;
  }

  const renderReasonPopover = ({ reason }: ReasonPopoverProps): JSX.Element => (
    <Popover id="popover-reason">
      <Popover.Body>{reason}</Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!id) {
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getAllSubTypesByBudgetId(token, id)
          .then((response) => {
            if (response.data.code === 200) {
              console.log("Sub Budget: ", response.data.data);
              setSubBudgetList(response.data.data.subBudgets);
            } else {
              console.error("Failed to fetch call stats");
            }
          })
          .catch((error) => {
            console.error("Error fetching call stats", error);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!id) {
      return;
    }

    setIsLoading(true); // Set loading to true before the API call

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .subBudgetTypeFundTransfer(token, id)
          .then((response) => {
            if (response.data.code === 200) {
              console.log("Sub Budget: ", response.data.data);
              setSubBudgetTransfer(response.data.data);
            } else {
              console.error("Failed to fetch sub-budget transfer data");
            }
          })
          .catch((error) => {
            console.error("Error fetching sub-budget transfer data", error);
          })
          .finally(() => {
            setIsLoading(false); // Set loading to false after the API call is complete
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [id, navigate]);

  const handleTransfer = () => {
    let isValid = true;

    if (transferValue === "") {
      setTransferValueIsValid(true);
      isValid = false;
    } else {
      setTransferValueIsValid(false);
    }

    if (transferFrom === "") {
      setTransferFromIsValid(true);
      isValid = false;
    } else {
      setTransferFromIsValid(false);
    }

    if (transferTo === "") {
      setTransferToIsValid(true);
      isValid = false;
    } else {
      setTransferToIsValid(false);
    }

    if (transferFrom === transferTo) {
      toast.error("Transfer From and Transfer To should not be the same");
      isValid = false;
    }

    const payload = {
      sourceSubBudgetId: transferFrom,
      targetSubBudgetId: transferTo,
      amount: transferValue,
    };

    if (isValid) {
      Swal.fire({
        title: "Do You Want To Transfer Funds?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Transfer",
        denyButtonText: `Don't Transfer`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Transferring Funds...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .subBudgetFundTransfer(token, employeeId, payload)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Fund Transfer Completed Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else if (response.data.code) {
                    toast.dismiss(toastId);
                    toast.error(response.data.message);
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Transfer Funds");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    }
  };

  const handleTransferClear = () => {
    setTransferValue("");
    setTransferFrom("");
    setTransferTo("");
    setTransferValueIsValid(false);
    setTransferFromIsValid(false);
    setTransferToIsValid(false);
  };

  interface FormatDate {
    (dateString: string): string;
  }

  const formatDate: FormatDate = (dateString) => {
    // Parse the date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "Invalid Date";
    }

    // Format the date as YYYY/MM/DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center ">
                  <div className="col-md-4">
                    <h3 className="page-title">
                      {budgetData[0]?.year} Annual Budget
                    </h3>
                  </div>
                  <div className="col-md-8 float-end ms-auto">
                    <div className="d-flex title-head">
                      {/* Back Button */}
                      <div className="col-2 text-end me-3">
                        {" "}
                        {/* Added 'me-3' for spacing */}
                        <Link
                          to="/budget/approvedBudgets"
                          className="btn btn-primary"
                        >
                          <i
                            className="feather-arrow-left"
                            style={{ fontSize: "17px", marginRight: "5px" }}
                          />
                          Back to list
                        </Link>
                      </div>
                      <div className="daterange-picker d-flex align-items-center justify-content-center">
                        <div className="head-icons mb-0">
                          <CollapseHeader />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {budgetData[0]?.status[0]?.name !== "Approved" ? (
            <div>
              <div className="col-md-12">
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "500px", // Ensure it takes full height of the row
                  }}
                >
                  <div className="card-header">
                    <h5 className="card-title">
                      Justification Documents & Notes
                    </h5>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto", // Make scrollable if content overflows
                    }}
                  >
                    <p>
                      <strong>Marketing Rejection Note:</strong>{" "}
                      {budgetData[0]?.rejectionNote
                        ? budgetData[0]?.rejectionNote
                        : "No Rejection Note"}
                    </p>

                    <p>
                      <strong>Marketing Approval Note:</strong>{" "}
                      {budgetData[0]?.reason
                        ? budgetData[0]?.reason
                        : "No Approval Note"}
                    </p>

                    <div className="row">
                      {attendaceDocuments.map(
                        (data: {
                          id: React.Key | null | undefined;
                          fileType: string;
                          fileName: any;
                          filePath: string | URL | undefined;
                        }) => (
                          <div className="col-md-3 mb-5" key={data.id}>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              {/* File Type Icon */}
                              <div className="mb-3">
                                {data.fileType === "pdf" ? (
                                  <ImageWithBasePath
                                    src="assets/img/pdf.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "image" ? (
                                  <ImageWithBasePath
                                    src="assets/img/picture.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "doc" ? (
                                  <ImageWithBasePath
                                    src="assets/img/doc.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "xls" ? (
                                  <ImageWithBasePath
                                    src="assets/img/xls.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : null}
                              </div>
                              {/* File Details */}
                              <div className="text-center">
                                <h6
                                  className="mb-2 text-truncate"
                                  style={{
                                    maxWidth: "150px", // Adjust width as per your layout
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={data.fileName} // Show full name on hover
                                >
                                  {data.fileName || "Placeholder Name"}
                                </h6>
                              </div>
                              {/* Action Buttons */}
                              <div className="d-flex justify-content-center">
                                <a
                                  href={data.filePath?.toString()} // File URL
                                  download={data.fileName} // Download file with its name
                                  className="btn btn-outline-primary me-2 btn-sm"
                                >
                                  Download File
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 d-flex">
                  <div className="card w-100">
                    {/* <div className="card-header border-0 pb-0"> */}
                    <div className="card-header">
                      <h5 className="card-title">Budget Status</h5>
                    </div>
                    {/* </div> */}
                    <div className="card-body">
                      <div id="leadpiechart">
                        <Chart
                          options={chartOptions}
                          series={chartOptions.series}
                          type={chartOptions.chart.type}
                          width={chartOptions.chart.width}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card w-100">
                    {/* <div className="card-header border-0 pb-0"> */}
                    <div className="card-header">
                      <h5 className="card-title">Sub-Budget Summary</h5>
                    </div>
                    {/* </div> */}
                    <div className="card-body">
                      <div id="leadpiechart">
                        <Chart
                          options={chartOptionsSubBudget}
                          series={chartOptionsSubBudget.series}
                          type={chartOptionsSubBudget.chart.type}
                          width={chartOptionsSubBudget.chart.width}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="card w-100">
                    {/* <div className="card-header border-0 pb-0"> */}
                    <div className="card-header">
                      <h5 className="card-title">
                        Approved Sub-Budget Type Summary
                      </h5>
                    </div>
                    {/* </div> */}
                    <div className="card-body">
                      <div id="leadpiechart">
                        <Chart
                          options={chartOptionsSubBudgetType}
                          series={chartOptionsSubBudgetType.series}
                          type={chartOptionsSubBudgetType.chart.type}
                          width={chartOptionsSubBudgetType.chart.width}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div
                    className="card "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px", // Ensure it takes full height of the row
                      width: "100%",
                    }}
                  >
                    <div className="card-header border-0 pb-0">
                      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4>
                          <i className="ti ti-grip-vertical me-1" />
                          Budget Top-Up
                        </h4>
                        <div className="d-flex gap-2">
                          {/* <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mt-1"
                            data-bs-toggle="modal"
                            data-bs-target="#con-fund-modal" // Ensure this matches the modal's id
                            disabled={
                              budgetData[0]?.status[0]?.name === "Approved"
                                ? false
                                : true
                            }
                          >
                            <i className="fas fa-exchange-alt"></i>{" "}
                            &nbsp;&nbsp;Fund Transfer
                          </button> */}
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mt-1"
                            data-bs-toggle="modal"
                            data-bs-target="#con-close-modal"
                            onClick={() => {
                              setInitalError(false);
                              setFieldErrors({ file: "" });
                            }}
                            disabled={
                              budgetData[0]?.status[0]?.name === "Approved"
                                ? false
                                : true
                            }
                          >
                            <i className="fas fa-wallet"></i>&nbsp;&nbsp;Top Up
                            Budget
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto", // Make scrollable if content overflows
                      }}
                    >
                      <div className="table-responsive custom-table">
                        <table className="table dataTable" id="deals-project">
                          <thead className="thead-light">
                            <tr>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Reason</th>
                              <th>Status</th>
                              <th>Rejection Note</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topUpData.map(
                              (
                                data: {
                                  topUpFileJustifications: never[];
                                  topUpDate:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  topUpAmount:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  status: {
                                    color: any;
                                    name:
                                      | string
                                      | number
                                      | boolean
                                      | React.ReactElement<
                                          any,
                                          | string
                                          | React.JSXElementConstructor<any>
                                        >
                                      | Iterable<React.ReactNode>
                                      | React.ReactPortal
                                      | null
                                      | undefined;
                                  }[];
                                },
                                index: React.Key | null | undefined
                              ) => (
                                <tr
                                  key={index}
                                  className={
                                    typeof index === "number" && index % 2 === 0
                                      ? "even"
                                      : "odd"
                                  }
                                >
                                  <td>{data.topUpDate}</td>
                                  <td>LKR {data.topUpAmount}</td>
                                  <td>
                                    {(data as any).reason.length > 20 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={renderReasonPopover({
                                          reason: (data as any).reason,
                                        })}
                                      >
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {(data as any).reason.substring(
                                            0,
                                            20
                                          )}
                                          ...
                                        </span>
                                      </OverlayTrigger>
                                    ) : (
                                      (data as any).reason
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      <span
                                        className={`badge badge-pill badge-status bg-${data.status[0]?.color}`}
                                      >
                                        {data.status[0]?.name}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    {(data as any).rejectionNote?.length >
                                    20 ? (
                                      <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="top"
                                        overlay={renderReasonPopover({
                                          reason: (data as any).rejectionNote,
                                        })}
                                      >
                                        <span
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {(
                                            data as any
                                          ).rejectionNote.substring(0, 20)}
                                          ...
                                        </span>
                                      </OverlayTrigger>
                                    ) : (data as any).rejectionNote ? (
                                      (data as any).rejectionNote
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-link text-info"
                                      title="Download Justification"
                                      onClick={() =>
                                        handleDownloadJustification(
                                          data.topUpFileJustifications || []
                                        )
                                      }
                                    >
                                      <i
                                        className="feather-download"
                                        style={{
                                          fontSize: "1.3rem",
                                          marginLeft: "10px",
                                        }}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex">
                  <div
                    className="card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px",
                      width: "100%",
                    }}
                  >
                    <div className="card-header border-0 pb-0">
                      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4>
                          <i className="ti ti-grip-vertical me-1" />
                          Budget Type Fund Transfer
                        </h4>
                        <div className="d-flex gap-2">
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light mt-1"
                            data-bs-toggle="modal"
                            data-bs-target="#con-fund-modal"
                            disabled={
                              budgetData[0]?.status[0]?.name === "Approved"
                                ? false
                                : true
                            }
                          >
                            <i className="fas fa-exchange-alt"></i>{" "}
                            &nbsp;&nbsp;Fund Transfer
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100%" }}
                        >
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="table-responsive custom-table">
                          <table className="table dataTable" id="deals-project">
                            <thead className="thead-light">
                              <tr>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Source Sub-Budget</th>
                                <th>Target Sub-Budget</th>
                              </tr>
                            </thead>
                            <tbody>
                              {subBudgetTransfer.map(
                                (
                                  data: {
                                    targetSubBudgetId: number;
                                    amount: number;
                                    status: string;
                                    sourceSubBudgetName: string;
                                    targetSubBudgetName: string;
                                    transferDate: string;
                                    sourceSubBudgetId: number;
                                  },
                                  index: React.Key | null | undefined
                                ) => (
                                  <tr
                                    key={index}
                                    className={
                                      typeof index === "number" &&
                                      index % 2 === 0
                                        ? "even"
                                        : "odd"
                                    }
                                  >
                                    <td>{data.transferDate}</td>
                                    <td>LKR {data.amount.toLocaleString()}</td>
                                    <td>
                                      <span
                                        className={`badge ${
                                          data.status === "Approved"
                                            ? "bg-success"
                                            : data.status === "Pending"
                                            ? "bg-warning"
                                            : data.status === "Rejected"
                                            ? "bg-danger"
                                            : data.status === "Expired"
                                            ? "bg-info"
                                            : "bg-secondary" // Default color for unknown status
                                        }`}
                                      >
                                        {data.status}
                                      </span>
                                    </td>
                                    <td>{data.sourceSubBudgetName}</td>
                                    <td>{data.targetSubBudgetName}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex">
                  <div
                    className="card "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px", // Ensure it takes full height of the row
                      width: "100%",
                    }}
                  >
                    <div className="card-header border-0 pb-0">
                      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <div className="col-md-12 m-2">
                          <h4>
                            <i className="ti ti-grip-vertical me-1" />
                            Sub-Budget Types Under {budgetData[0]?.year} Budget
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto", // Make scrollable if content overflows
                      }}
                    >
                      <div className="table-responsive custom-table">
                        <table className="table dataTable" id="deals-project">
                          <thead className="thead-light">
                            <tr>
                              <th>Sub-Type Name</th>
                              <th>Approved Budget</th>
                              <th>Remaining Budget</th>
                              <th>Requested Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {budgetData.map(
                              (
                                budget: {
                                  id: number;
                                  subBudgets: Array<{
                                    id: number;
                                    name: string;
                                    approvedAmount: number;
                                    remainingAmount: number;
                                    createdDate: string;
                                  }>;
                                },
                                index: React.Key | null | undefined
                              ) =>
                                budget.subBudgets.map((subBudget, subIndex) => (
                                  <tr
                                    key={`${index}-${subIndex}`}
                                    className={
                                      typeof subIndex === "number" &&
                                      subIndex % 2 === 0
                                        ? "even"
                                        : "odd"
                                    }
                                  >
                                    <td>{subBudget.name}</td>
                                    <td>
                                      LKR{" "}
                                      {subBudget.approvedAmount.toLocaleString()}
                                    </td>
                                    <td>
                                      LKR{" "}
                                      {subBudget.remainingAmount.toLocaleString()}
                                    </td>
                                    <td>
                                      {subBudget.createdDate &&
                                      typeof subBudget.createdDate === "string"
                                        ? new Date(subBudget.createdDate)
                                            .toISOString()
                                            .slice(0, 10)
                                        : "Invalid Date"}
                                    </td>
                                  </tr>
                                ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px", // Ensure it takes full height of the row
                    }}
                  >
                    <div className="card-header">
                      <h5 className="card-title">Approval/ Rejection Notes</h5>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto", // Make scrollable if content overflows
                      }}
                    >
                      <p>
                        <strong>Marketing Rejection Note:</strong>{" "}
                        {budgetData[0]?.rejectionNote
                          ? budgetData[0]?.rejectionNote
                          : "N/A"}
                      </p>

                      <p>
                        <strong>Marketing Approval Note:</strong>{" "}
                        {budgetData[0]?.reason ? budgetData[0]?.reason : "N/A"}
                      </p>

                      <p>
                        <strong>Finance Approval/ Rejection Note:</strong>{" "}
                        {budgetData[0]?.financeApproveRejectNote
                          ? budgetData[0]?.financeApproveRejectNote
                          : "N/A"}
                      </p>

                      {/* <p>
                        <strong>President Approval/ Rejection Note:</strong>{" "}
                        {budgetData[0]?.presidentApproveRejectNote
                          ? budgetData[0]?.presidentApproveRejectNote
                          : "N/A"}
                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="card"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px", // Ensure it takes full height of the row
                    }}
                  >
                    <div className="card-header">
                      <h5 className="card-title">Justification Documents</h5>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto", // Make scrollable if content overflows
                      }}
                    >
                      <div className="row">
                        {attendaceDocuments.map(
                          (data: {
                            id: React.Key | null | undefined;
                            fileType: string;
                            fileName: any;
                            filePath: string | URL | undefined;
                          }) => (
                            <div className="col-md-4 mb-4" key={data.id}>
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                {/* File Type Icon */}
                                <div className="mb-3">
                                  {data.fileType === "pdf" ? (
                                    <ImageWithBasePath
                                      src="assets/img/pdf.png"
                                      className="img-fluid"
                                      alt="Logo"
                                      style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                    />
                                  ) : data.fileType === "image" ? (
                                    <ImageWithBasePath
                                      src="assets/img/picture.png"
                                      className="img-fluid"
                                      alt="Logo"
                                      style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                    />
                                  ) : data.fileType === "doc" ? (
                                    <ImageWithBasePath
                                      src="assets/img/doc.png"
                                      className="img-fluid"
                                      alt="Logo"
                                      style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                    />
                                  ) : data.fileType === "xls" ? (
                                    <ImageWithBasePath
                                      src="assets/img/xls.png"
                                      className="img-fluid"
                                      alt="Logo"
                                      style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                    />
                                  ) : null}
                                </div>
                                {/* File Details */}
                                <div className="text-center">
                                  <h6
                                    className="mb-2 text-truncate"
                                    style={{
                                      maxWidth: "150px", // Adjust width as per your layout
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                    title={data.fileName} // Show full name on hover
                                  >
                                    {data.fileName || "Placeholder Name"}
                                  </h6>
                                </div>
                                {/* Action Buttons */}
                                <div className="d-flex justify-content-center">
                                  <a
                                    href={data.filePath?.toString()} // File URL
                                    download={data.fileName} // Download file with its name
                                    className="btn btn-outline-primary me-2 btn-sm"
                                  >
                                    Download File
                                  </a>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 d-flex">
                  <div
                    className="card "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "400px", // Ensure it takes full height of the row
                      width: "100%",
                    }}
                  >
                    <div className="card-header border-0 pb-0">
                      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <div className="col-md-12 m-2">
                          <h4>
                            <i className="ti ti-grip-vertical me-1" />
                            Campaigns Under {budgetData[0]?.year} Budget
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div
                      className="card-body"
                      style={{
                        overflowY: "auto", // Make scrollable if content overflows
                      }}
                    >
                      <div className="table-responsive custom-table">
                        <table className="table dataTable" id="deals-project">
                          <thead className="thead-light">
                            <tr>
                              <th>Name</th>
                              <th>Allocated Budget</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignData.map(
                              (
                                campaign: {
                                  id: number;
                                  name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  allocatedBudget:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  startDate:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                  endDate:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                },
                                index: React.Key | null | undefined
                              ) => (
                                <tr
                                  key={index}
                                  className={
                                    typeof index === "number" && index % 2 === 0
                                      ? "even"
                                      : "odd"
                                  }
                                >
                                  <td>{campaign.name}</td>
                                  <td>LKR {campaign.allocatedBudget}</td>
                                  <td>
                                    {campaign.startDate &&
                                    typeof campaign.startDate === "string"
                                      ? new Date(campaign.startDate)
                                          .toISOString()
                                          .slice(0, 10)
                                      : "Invalid Date"}
                                  </td>
                                  <td>
                                    {campaign.endDate &&
                                    typeof campaign.endDate === "string"
                                      ? new Date(campaign.endDate)
                                          .toISOString()
                                          .slice(0, 10)
                                      : "Invalid Date"}
                                  </td>
                                  <td>
                                    <Link
                                      to={`${route.viewCampaign
                                        .replace(":id", campaign.id.toString())
                                        .replace(":status", "true")}`}
                                      className="text-info"
                                      title="View Details"
                                    >
                                      <i
                                        className="ti ti-eye"
                                        style={{
                                          fontSize: "1.3rem",
                                          marginLeft: "10px",
                                        }}
                                      ></i>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="modal fade"
          id="con-close-modal"
          tabIndex={-1}
          aria-labelledby="con-close-modal-label"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="con-close-modal-label">
                  Top Up {budgetData[0]?.year} Budget
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body p-4">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="field-2" className="form-label">
                      Top Up Amount
                    </label>
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        LKR
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="field-2"
                        placeholder="50000.00"
                        aria-describedby="basic-addon1"
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                      />
                    </div>
                    {fieldErrors.budget !== "" ? (
                      <div className="text-danger">{fieldErrors.budget}</div>
                    ) : null}
                    <div className="col-md-12 mt-3">
                      <label htmlFor="note" className="form-label">
                        Note
                      </label>
                      <textarea
                        className="form-control"
                        id="note"
                        rows={3}
                        placeholder="Enter your note here"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      ></textarea>
                    </div>
                    {fieldErrors.note !== "" ? (
                      <div className="text-danger">{fieldErrors.note}</div>
                    ) : null}
                  </div>
                  <div className="modal-body">
                    <SingleFileUpload
                      onFileSelect={handleFileSelect}
                      clear={clearFileUploader}
                    />
                    {fieldErrors.file !== "" ? (
                      <div className="text-danger">{fieldErrors.file}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-cancel waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleClear}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="con-fund-modal" // Ensure this matches the button's data-bs-target
        // tabIndex={-1}
        aria-labelledby="con-fund-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" style={{ maxWidth: "600px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="con-fund-modal-label">
                Fund Transfer for {budgetData[0]?.year} Budget
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleTransferClear}
              ></button>
            </div>

            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Source Sub-Budget
                  </label>
                  <select
                    className={`form-select ${
                      transferFromIsValid ? "is-invalid" : ""
                    }`}
                    id="validationCustomContactMethod"
                    value={transferFrom}
                    onChange={(e) => {
                      setTransferFrom(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select an option</option>
                    {subBudgetList.map((budget: SubBudget) => (
                      <option key={budget.id} value={budget.id}>
                        {budget.name}
                      </option>
                    ))}
                  </select>
                  {transferFromIsValid && (
                    <div className="invalid-feedback">
                      Please select a transfer from type
                    </div>
                  )}
                </div>

                <div className="col-md-6 mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Target Sub-Budget
                  </label>
                  <select
                    className={`form-select ${
                      transferToIsValid ? "is-invalid" : ""
                    }`}
                    id="validationCustomContactMethod"
                    value={transferTo}
                    onChange={(e) => {
                      setTransferTo(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select an option</option>
                    {subBudgetList.map((budget: SubBudget) => (
                      <option key={budget.id} value={budget.id}>
                        {budget.name}
                      </option>
                    ))}
                  </select>
                  {transferToIsValid && (
                    <div className="invalid-feedback">
                      Please select a transfer to type
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <label htmlFor="field-2" className="form-label">
                  Fund Transfer Amount
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    LKR
                  </span>
                  <input
                    type="text"
                    className={`form-control ${
                      transferValueIsValid ? "is-invalid" : ""
                    }`}
                    id="field-2"
                    placeholder="50000.00"
                    aria-describedby="basic-addon1"
                    value={transferValue}
                    onChange={(e) => setTransferValue(e.target.value)}
                  />
                  {transferValueIsValid && (
                    <div className="invalid-feedback">
                      Enter the transfer amount
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                data-bs-dismiss="modal"
                onClick={handleTransferClear}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleTransfer}
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBudget;
