import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import apiDefinitions from "../../api/apiDefinitions";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import toast from "react-hot-toast";
import { du } from "@fullcalendar/core/internal-common";
import { duration } from "../../core/common/selectoption/selectoption";

const Login = () => {
  const navigate = useNavigate();
  const route = all_routes;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem("menuOpened", "Dashboard");

    // Add event listener for the Enter key
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
      handleSignIn(event as unknown as React.MouseEvent);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Clean up the event listener
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [email, password]);

  const handleSignIn = (event: React.MouseEvent) => {
    event.preventDefault(); // Prevent the default link behavior

    console.log("Sign In");

    const payload = {
      username: email,
      password: password,
    };

    const loading = toast.loading("Signing in...");

    apiDefinitions
      .login(payload)
      .then((response) => {
        console.log("Response", response);

        if (response.status === 200) {
          toast.dismiss(loading);
          toast.success("Sign in successful!", { duration: 3000 });
          sessionStorage.setItem("token", response.data.accessToken);
          setToastMessage("Sign in successful!");
          setShowToast(true);

          navigate(route.leadsDashboard); // Navigate to the deals dashboard
        } else {
          toast.dismiss(loading);
          toast.error("Invalid credentials");
          console.error("Invalid credentials");
        }
      })
      .catch((error) => {
        toast.dismiss(loading);
        toast.error("Invalid credentials");
        console.error("Error during sign in", error);
      });

    console.log("Payload", payload);
  };

  return (
    <div className="account-content">
      <div className="d-flex flex-wrap w-100 vh-100 overflow-hidden account-bg-01">
        <div className="d-flex align-items-center justify-content-center flex-wrap vh-100 overflow-auto p-4 w-50 bg-backdrop">
          <form className="flex-fill">
            <div className="mx-auto mw-450">
              <div className="d-flex align-items-center mb-4">
                <ImageWithBasePath
                  src="assets/img/KIU_LOGO_LOGIN.png"
                  className="img-fluid"
                  alt="Logo"
                  style={{ width: "100px", height: "auto" }}
                />

                <span
                  className="ml-3"
                  style={{
                    marginLeft: "20px",
                    fontSize: "60px",
                    fontWeight: "500",
                    color: "#ed1f24",
                    fontFamily: "Georgia, serif",
                  }}
                >
                  KIU CRM
                </span>
              </div>
              <div className="mb-4">
                <h4>Sign In</h4>
                {/* <p>Access the CRMS panel using your email and passcode.</p> */}
              </div>
              <div className="mb-3">
                <label className="col-form-label">Email Address</label>
                <div className="position-relative">
                  <span className="input-icon-addon">
                    <i className="ti ti-mail"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">Password</label>
                <div className="pass-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="pass-input form-control"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span
                    className={`ti toggle-password ${
                      isPasswordVisible ? "ti-eye" : "ti-eye-off"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></span>
                </div>
              </div>

              {/* <div className="mb-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    handleSignIn();
                  }}
                >
                  Sign In
                </button>
              </div> */}

              <Link
                to={route.dealsDashboard}
                className="btn btn-primary w-100"
                onClick={handleSignIn}
              >
                Sign In
              </Link>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Login;
