import React, { useEffect, useState, useRef } from "react";
import apiDefinitions from "../../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Link } from "react-router-dom";
import { Pagination, Form, OverlayTrigger, Popover } from "react-bootstrap";

import toast from "react-hot-toast";
import Swal from "sweetalert2";

const SubBudgetFundApproval = () => {
  const route = all_routes;
  const navigate = useNavigate();

  const [employeeId, setEmployeeId] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");
  const [leadPriority, setLeadPriority] = useState("");
  const [leadId, setLeadId] = useState<number | null>(null);
  const [fullName, setFullName] = useState("");
  const [assignedBy, setAssignedBy] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [description, setDescription] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [isNoteValid, setIsNoteValid] = useState(false);
  const [isStatusValid, setIsStatusValid] = useState(false);

  const [allLeads, setAllLeads] = useState<Lead[]>([]); // Store all fetched leads
  const [leads, setLeads] = useState<Lead[]>([]); // Store the current page's leads
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [campaignName, setCampaignName] = useState("");
  const [topUpAmount, setTopUpAmount] = useState(0);
  const [reason, setReason] = useState("");
  const [topUpDate, setTopUpDate] = useState("");

  const [allTransfers, setAllTransfers] = useState<Transfer[]>([]); // Store all fetched transfers
  const [transfers, setTransfers] = useState<Transfer[]>([]); // Store the current page's transfers
  const [selectedTransfer, setSelectedTransfer] = useState<Transfer | null>(
    null
  );
  const hasDataLoaded = useRef(false);

  interface Transfer {
    recordId: number;
    budgetId: number;
    sourceSubBudgetName: number;
    targetSubBudgetName: number;
    amount: number;
    transferDate: string;
    transferBy: string;
    transferDescription: string;
    status: {
      id: number;
      name: string;
      color: string;
    };
    approveRejectBy: string | null;
    approveRejectNote: string | null;
    approveRejectDate: string | null;
  }

  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Lead {
    id: number;
    campaignName: string;
    topUpAmount: number;
    reason: string;
    topUpDate: string;
    status: string;
  }

  interface FetchLeadsResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  interface ReasonPopoverProps {
    reason: string;
  }

  const renderReasonPopover = ({ reason }: ReasonPopoverProps): JSX.Element => (
    <Popover id="popover-reason">
      <Popover.Body>{reason}</Popover.Body>
    </Popover>
  );

  const truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  //   useEffect(() => {
  //     const startIndex = (currentPage - 1) * itemsPerPage;
  //     const endIndex = startIndex + itemsPerPage;
  //     setTransfers(allTransfers.slice(startIndex, endIndex));
  //   }, [currentPage, itemsPerPage, allTransfers]);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  //   const handleItemsPerPageChange = (
  //     event: React.ChangeEvent<HTMLSelectElement>
  //   ) => {
  //     const items = parseInt(event.target.value, 10);
  //     setItemsPerPage(items);
  //     setCurrentPage(1); // Reset to the first page
  //     setAllTransfers([]); // Clear existing data to refetch
  //   };

  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  const handleAssignTransfer = (transfer: Transfer) => {
    setSelectedTransfer(transfer);
  };

  const handleClose = () => {
    setSearchClicked(!searchClicked);
    setSearchKey("");
  };

  const handleAssignLead = (
    leadId: number,
    campaignName: string,
    topUpAmount: number,
    reason: string,
    topUpDate: string
  ) => {
    setLeadId(leadId);
    setCampaignName(campaignName);
    setTopUpAmount(topUpAmount);
    setReason(reason);
    setTopUpDate(topUpDate);
  };

  useEffect(() => {
    console.log("approvalStatus:", approvalStatus);
  }, [approvalStatus]);

  const handleSubmitFundApproval = () => {
    let isValid = true;
    if (!description) {
      isValid = false;
      setIsNoteValid(true);
    } else {
      setIsNoteValid(false);
    }

    if (approvalStatus === "") {
      isValid = false;
      setIsStatusValid(true);
    } else {
      setIsStatusValid(false);
    }

    if (!isValid) {
      console.error("Form validation failed.");
      return; // Stop execution if validation fails
    }

    console.log("Submitted");
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    const payload = {
      action: approvalStatus,
      approveRejectNote: description,
      transferId: selectedTransfer?.recordId,
      userId: employeeId,
    };

    if (isValid) {
      Swal.fire({
        title: "Do You Want To Submit?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const loading = toast.loading("Saving Changes...");
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }
          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp && decodedToken.exp > currentTime) {
              apiDefinitions
                .approveRejectFundApproval(token, payload)
                .then((response: any) => {
                  const data = response.data.data;
                  console.log("Lead Data By ID: ", data);
                  setRefresh(!refresh);
                  toast.dismiss(loading);
                  // Close the modal
                  // const modalElement = document.getElementById("add_faq");
                  // if (modalElement) {
                  //   modalElement.classList.remove("show");
                  //   modalElement.style.display = "none";
                  //   const backdrop = document.querySelector(".modal-backdrop");
                  //   if (backdrop) {
                  //     backdrop.remove();
                  //   }
                  // }
                  //   window.location.reload();
                  // Show success Swal
                  Swal.fire({
                    icon: "success",
                    title: "Approval Submitted Successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((error) => {
                  toast.dismiss(loading);
                  console.error("Error approving lead:", error);
                  Swal.fire({
                    icon: "error",
                    title: "Failed to Save Changes",
                    text: "An error occurred while saving changes. Please try again.",
                  });
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate("/login");
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          // Show toast for not saving
          toast("Changes Were Not Saved", {
            icon: "⚠️",
          });
        }
      });
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getMarketingEmployeeList(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setEmployeeList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (!leadId) {
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    // if (!hasImageLoaded.current) {
    //   hasImageLoaded.current = true;
    //   return;
    // }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .viewTopUpJustification(token, leadId)
          .then((response: any) => {
            if (response.data.code === 200) {
              // Ensure you access the correct key
              setUploadAttendaceDocuments(
                response.data.data.topUpFileJustifications || []
              );
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [leadId]);

  useEffect(() => {
    if (Array.isArray(uploadAttendaceDocuments)) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is not an array
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments]);

  const handleClear = () => {
    setLeadId(null);
    setCampaignName("");
    setTopUpAmount(0);
    setReason("");
    setTopUpDate("");
    setDescription("");
    setApprovalStatus("");
  };

  const fetchTransfers = async (page: number, size: number): Promise<void> => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Redirecting to login.");
      navigate("/login");
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    const loadingToastId = toast.loading("Loading Pending Transfers...");
    try {
      const response = await apiDefinitions.getPendingSubBudgetFundApproval(
        token,
        page - 1, // Convert to 0-based index for the API
        size
      );
      const data = response.data;

      if (data && data.data) {
        const transfers = data.data.content.map((transfer: any) => ({
          ...transfer,
          status: {
            id: 1, // Default status ID (you can adjust this based on your needs)
            name: "Pending", // Default status name
            color: "warning", // Default status color (e.g., Bootstrap's warning color)
          },
        }));

        setAllTransfers(transfers);
        setTotalPages(data.data.totalPages);
        setTransfers(transfers); // Set the current page's transfers
      } else {
        console.error("Unexpected response format:", data);
      }
    } catch (error) {
      console.error("Failed to fetch transfers:", error);
    } finally {
      toast.dismiss(loadingToastId);
    }
  };

  useEffect(() => {
    fetchTransfers(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  const formatDate = (dateString: string): string => {
    const parts = dateString.split(" ");
    const monthMap: { [key: string]: string } = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const day = parts[2];
    const month = monthMap[parts[1]];
    const year = parts[5];
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">
                      {" "}
                      Sub-Budget Fund Transfer Approval
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                      <Form.Select
                        value={itemsPerPage}
                        onChange={(event) =>
                          setItemsPerPage(parseInt(event.target.value, 10))
                        }
                        aria-label="Select number of items per page"
                        className="w-auto"
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>

                      {/* <div className="col-md-4 mb-3 mt-3">
                        <select
                          className={`form-select`}
                          id="validationCustomContactMethod"
                          value={leadStatus}
                          onChange={(e) => setLeadStatus(e.target.value)}
                          required
                        >
                          <option value="">Select an option</option>
                          <option value="1">New</option>
                          <option value="2">Contacted</option>
                          <option value="3">Interested</option>
                          <option value="4">Application in Progress</option>
                          <option value="5">Enrolled</option>
                          <option value="6">Disqualified</option>
                          <option value="7">Follow-Up Needed</option>
                          <option value="8">Lost</option>
                        </select>
                      </div>

                      <div className="col-md-4">
                        <select
                          className={`form-select`}
                          id="validationCustomContactMethod"
                          value={leadPriority}
                          onChange={(e) => setLeadPriority(e.target.value)}
                          required
                        >
                          <option value="">Select an option</option>
                          <option value="1">Low </option>
                          <option value="2">Medium</option>
                          <option value="3">High </option>
                        </select>
                      </div>

                      <div className="col-md-2">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder="Search"
                          value={searchKey}
                          onChange={(e) => setSearchKey(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-1">
                        <button
                          className="btn btn-primary"
                          onClick={searchClicked ? handleClose : handleSearch}
                        >
                          <i
                            className={`fas ${
                              searchClicked ? "fa-times" : "fa-filter"
                            }`}
                            style={{
                              marginRight: "5px",
                              padding: "4px",
                              fontSize: "15px",
                              marginLeft: "1px",
                            }}
                          ></i>
                        </button>
                      </div> */}
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table text-nowrap table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Source Sub-Budget</th>
                            <th scope="col">Target Sub-Budget</th>
                            <th scope="col">Amount (LKR)</th>
                            <th scope="col">Transfer Date</th>
                            <th scope="col">Description</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transfers.map((transfer, index) => (
                            <tr key={index}>
                              <td>{transfer.sourceSubBudgetName}</td>
                              <td>{transfer.targetSubBudgetName}</td>
                              <td>{transfer.amount.toLocaleString()}</td>
                              <td>{formatDate(transfer.transferDate)}</td>
                              <td>
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  overlay={
                                    <Popover
                                      id={`popover-description-${index}`}
                                    >
                                      <Popover.Body>
                                        {transfer.transferDescription ||
                                          "No description available"}
                                      </Popover.Body>
                                    </Popover>
                                  }
                                >
                                  <span>
                                    {truncateText(
                                      transfer.transferDescription ||
                                        "No description",
                                      30
                                    )}
                                  </span>
                                </OverlayTrigger>
                              </td>
                              <td>
                                <span
                                  className={`badge bg-${transfer.status.color}`}
                                >
                                  {transfer.status.name}
                                </span>
                              </td>
                              <td>
                                <div className="hstack gap-2 fs-15">
                                  <Link
                                    to="#"
                                    className="btn btn-info"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_faq"
                                    onClick={() =>
                                      handleAssignTransfer(transfer)
                                    }
                                  >
                                    <i
                                      className="feather-check-circle"
                                      style={{ fontSize: "17px" }}
                                    />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination className="mt-3 justify-content-center">
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, index) => (
                        <Pagination.Item
                          key={index + 1}
                          active={index + 1 === currentPage}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="add_faq" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Fund Transfer Approval</h5>
              <div className="d-flex align-items-center mod-toggle">
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleClear();
                  }}
                >
                  <i className="ti ti-x" />
                </button>
              </div>
            </div>
            <form>
              <div className="modal-body">
                <p>
                  <strong>Source Sub-Budget: </strong>{" "}
                  {selectedTransfer?.sourceSubBudgetName}
                </p>
                <p>
                  <strong>Target Sub-Budget: </strong>{" "}
                  {selectedTransfer?.targetSubBudgetName}
                </p>
                <p>
                  <strong>Amount (LKR): </strong>{" "}
                  {selectedTransfer?.amount.toLocaleString()}
                </p>
                <p>
                  <strong>Description: </strong>{" "}
                  {selectedTransfer?.transferDescription || "No description"}
                </p>
                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Approval Note
                  </label>
                  <textarea
                    rows={1}
                    cols={5}
                    className={`form-control ${
                      isNoteValid ? "is-invalid" : ""
                    }`}
                    placeholder="Enter text here"
                    defaultValue={""}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {isNoteValid && (
                    <div className="invalid-feedback">
                      Please enter a description.
                    </div>
                  )}
                </div>

                <div className="mb-3">
                  {" "}
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    {" "}
                    Approval Status{" "}
                  </label>{" "}
                  <select
                    className={`form-select ${
                      isStatusValid ? "is-invalid" : ""
                    }`}
                    id="validationCustomContactMethod"
                    value={approvalStatus}
                    onChange={(e) => setApprovalStatus(e.target.value)}
                    required
                  >
                    {" "}
                    <option value="">Select an option</option>{" "}
                    <option value="approved">Approve </option>{" "}
                    <option value="rejected">Reject</option>{" "}
                  </select>{" "}
                  {isStatusValid && (
                    <div className="invalid-feedback">
                      Please select an approval status.
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <div className="d-flex align-items-center justify-content-end m-0">
                  <Link
                    to="#"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleClear();
                    }}
                  >
                    Cancel
                  </Link>
                  <button
                    type="button"
                    // data-bs-dismiss="modal"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmitFundApproval();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubBudgetFundApproval;
