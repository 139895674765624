import React, { useEffect, useState, useRef } from "react";
import apiDefinitions from "../../../../api/apiDefinitions";
import { useNavigate, useParams, Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Card } from "react-bootstrap";
import { OverlayTrigger, Popover, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import FileUpload from "./fileUploader";
import SingleFileUpload from "./approvedBudgets/singleFileUploader";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";

const ViewCampaign = () => {
  const route = all_routes;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [goal, setGoal] = useState("");
  const [allocatedBudget, setAllocatedBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subBudget, setSubBudget] = useState("");
  const hasImageLoaded = useRef(false);
  const [isJustificationModalOpen, setIsJustificationModalOpen] =
    useState(false);

  const [isExpenseNameValid, setIsExpenseNameValid] = useState(false);
  const [expenseName, setExpenseName] = useState("");
  const [isExpenseValueValid, setIsExpenseValueValid] = useState(false);
  const [expenseValue, setExpenseValue] = useState("");
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [clearFileTopUploader, setClearFileTopUploader] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [uploadedTopUpFile, setUploadedTopUpFile] = useState<File[]>([]);
  const [coursesCategoryList, setCoursesCategoryList] = useState<any[]>([]);
  const [expenseType, setExpenseType] = useState("");
  const [expensesId, setExpensesId] = useState("");
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [isTopUpJustificationModalOpen, setIsTopUpJustificationModalOpen] =
    useState(false);
  const [topUpJustificationDocuments, setTopUpJustificationDocuments] =
    useState<any[]>([]);

  const [campaignsType, setCampaignsType] = useState("");
  const [campaignsSubType, setCampaignsSubType] = useState("");

  const [expenses, setExpenses] = useState<
    {
      id: number;
      expenseTypeName: string;
      amount: number;
      approveStatusName: string;
      typeReason: string;
      expenseType: string;
      statusColor: string;
      note: string;
      date: string;
      approveRejectDate: string;
    }[]
  >([]);
  const [reload, setReload] = useState(false);

  const [topUp, setTopUp] = useState<
    {
      id: number;
      topUpAmount: string;
      topUpBy: number;
      topUpDate: string;
      status: string;
      statusId: number;
      statusColor: string;
      statusName: string;
      note: string;
      topUpApproveRejectDate: string;
    }[]
  >([]);
  const [refresh, setRefresh] = useState(false);
  const [totalExpenses, setTotalExpenses] = useState("");
  const [remainingBudget, setRemainingBudget] = useState("");

  const [employeeId, setEmployeeId] = useState("");
  const [campaignStatus, setCampaignStatus] = useState("");
  const [campaignTypeName, setCampaignTypeName] = useState("");
  const [campaignSubTypeName, setCampaignSubTypeName] = useState("");
  const [isFileUploadValid, setIsFileUploadValid] = useState(true);

  const { id, status } = useParams<{ id: string; status: string }>();
  const [redirectStatus, setRedirectStatus] = useState(
    status === "false" ? false : true
  );

  const [leadCount, setLeadCount] = useState("");

  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);
  const [isTypeValid, setIsTypeValid] = useState(false);
  const [displayExpenseType, setDisplayExpenseType] = useState("");
  const [budget, setBudget] = useState("");
  const [note, setNote] = useState("");
  const [viewJustificationById, setViewJustificationById] = useState<number>(0);

  const [isTopUpValid, setIsTopUpValid] = useState(false);
  const [isNoteValid, setIsNoteValid] = useState(false);
  const [isTopUpFileValid, setIsTopUpFileValid] = useState(true);
  const [approvalNote, setApprovalNote] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [topUpId, setTopUpId] = useState<number>(0);
  const [isApprovalStatusValid, setIsApprovalStatusValid] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [displayExpenseName, setDisplayExpenseName] = useState("");
  const [displayExpenseValue, setDisplayExpenseValue] = useState("");
  const [expenseId, setExpenseId] = useState<number>(0);

  const [campaignData, setCampaignData] = useState<{
    name: string;
    description: string;
    goal: string;
    allocatedBudget: string;
    startDate: string;
    endDate: string;
    channels: Channel[];
  }>({
    name: "",
    description: "",
    goal: "",
    allocatedBudget: "",
    startDate: "",
    endDate: "",
    channels: [],
  });

  const [channels, setChannels] = useState<Channel[]>([]);
  const [targetAudience, setTargetAudience] = useState<
    { label: string; value: string }[]
  >([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCampaignDetails();
  }, []);

  const fetchCampaignDetails = async () => {
    const token = sessionStorage.getItem("token");

    try {
      const response = await apiDefinitions.getCampaignDetailsById(token, id);
      const { data } = response.data;

      const campaignTypeName = data.campaigns_types[0]?.name || "N/A";
      const campaignSubTypeName = data.campaign_sub_types[0]?.name || "N/A";
      setCampaignTypeName(campaignTypeName);
      setCampaignSubTypeName(campaignSubTypeName);

      setBasicCampaignDetails(data);
      setFormattedTargetAudience(data.targetAudience);
      setMappedChannels(data.channels);
    } catch (error) {
      console.error("Error fetching campaign details:", error);
    }

    interface ChannelData {
      name: string;
      channelTypes: ChannelTypeData[];
      people: Person[];
    }
  };

  interface TargetAudience {
    label: string;
    value: string;
  }

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface CampaignData {
    name: string;
    campaigns_types: string;
    goal: string;
    allocatedBudget: string;
    leadCount: string;
    description: string;
    startDate: string;
    endDate: string;
    campaign_sub_types: string;
    status: string;
    subBudget: { name: string }[];
  }

  const setBasicCampaignDetails = (data: CampaignData) => {
    setName(data.name);
    setGoal(data.goal);
    setAllocatedBudget(data.allocatedBudget);
    setLeadCount(data.leadCount);
    setDescription(data.description);
    setSubBudget(data.subBudget[0]?.name);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setCampaignsType(data.campaigns_types);
    setCampaignsSubType(data.campaign_sub_types);
    setCampaignStatus(data.status);
    console.log("Campaign Statusssssssssssssssssssssssss", campaignStatus);
  };

  interface Audience {
    name: string;
    id: number;
  }

  interface FormattedAudience {
    label: string;
    value: string;
  }

  useEffect(() => {
    console.log("Redirect Status", redirectStatus);
  }, [redirectStatus]);

  const setFormattedTargetAudience = (audienceList: Audience[]) => {
    const formattedTargetAudiences: FormattedAudience[] = audienceList.map(
      (audience) => ({
        label: audience.name, // Use the "name" field from the API response
        value: audience.id.toString(), // Convert ID to string for consistency
      })
    );
    setTargetAudience(formattedTargetAudiences);
  };

  interface ChannelSubType {
    id: number;
    name: string;
  }

  interface ChannelType {
    id: number;
    name: string;
    channelSubTypes: ChannelSubType[];
  }

  interface Person {
    serial_number: number;
    name_in_full: string;
  }

  interface Channel {
    name: string;
    channelTypes: ChannelType[];
    people: Person[];
  }

  const setMappedChannels = (channelsData: any[]) => {
    const mappedChannels: Channel[] = channelsData.map(mapChannel);
    setChannels(mappedChannels);
    setCampaignData((prevData) => ({
      ...prevData,
      channels: mappedChannels,
    }));
  };

  interface ChannelData {
    name: string;
    channelTypes: ChannelTypeData[];
    people: Person[];
  }

  interface ChannelTypeData {
    id: number;
    name: string;
    channelSubTypes: ChannelSubTypeData[];
  }

  interface ChannelSubTypeData {
    id: number;
    name: string;
  }

  const mapChannel = (channel: ChannelData): Channel => {
    return {
      name: channel.name,
      channelTypes: channel.channelTypes?.map(mapChannelType) || [],
      people: channel.people || [],
    };
  };

  const mapChannelType = (type: ChannelTypeData): ChannelType => {
    return {
      id: type.id,
      name: type.name,
      channelSubTypes: type.channelSubTypes?.map(mapChannelSubType) || [],
    };
  };

  const mapChannelSubType = (subType: ChannelSubTypeData): ChannelSubType => {
    return {
      id: subType.id,
      name: subType.name,
    };
  };

  const handleBack = () => {
    if (redirectStatus === true) {
      navigate(-1);
    } else {
      navigate(all_routes.campaign);
    }
  };

  useEffect(() => {
    // refreshCampaigns();
  }, [reload]);

  const renderPopover = (channel: Channel) => (
    <Popover id={`popover-${channel.name}`}>
      <Popover.Header as="h3">{channel.name}</Popover.Header>
      <Popover.Body>
        {channel.channelTypes.map((type: ChannelType, typeIndex: number) => (
          <div key={typeIndex} className="mb-3">
            <h6 className="text-secondary">Type: {type.name}</h6>
            <ul className="list-unstyled ms-3">
              <h6 className="text-muted">Subtypes:</h6>
              {type.channelSubTypes.map(
                (subType: ChannelSubType, subTypeIndex: number) => (
                  <li key={subTypeIndex}>{subType.name}</li>
                )
              )}
            </ul>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (id) {
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          apiDefinitions.getCampaignSummary(token, id).then((response: any) => {
            const { data } = response.data;
            console.log("Expenses List", data);

            setTotalExpenses(data.totalExpenses);
            setRemainingBudget(data.remainingBudget);
          });
        } else {
          console.log("Session expired. Please log in again.");
          navigate(route.login);
        }
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [id, refresh]);

  const handleClear = () => {
    setExpenseName("");
    setExpenseValue("");
    setExpenseType("");
    setUploadedFile([]);
    setClearFileUploader(true);
    setIsFileUploadValid(true);
    setIsExpenseValueValid(false);
    setIsExpenseNameValid(false);
    setIsTypeValid(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getExpensesTypes(token).then((response: any) => {
          setCoursesCategoryList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  const handleApproveExpense = (
    expenseId: number,
    expenseName: string,
    expenseAmount: number,
    expenseType: string
  ) => {
    setExpenseId(expenseId);
    setDisplayExpenseName(expenseName);
    setDisplayExpenseValue(expenseAmount.toString());
    setDisplayExpenseType(expenseType);
  };

  const handleApproveTopUp = (topUpId: number) => {
    setTopUpId(topUpId);
    setShowTopUpModal(true);
  };

  const [expenseNote, setExpenseNote] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [expenseDescription, setExpenseDescription] = useState("");

  const handleViewJustification = async (
    expenseId: number,
    expenseNote: string,
    expenseDate: string,
    expenseDescription: string
  ) => {
    console.log("View Justification for Expense ID:", expenseNote);
    setExpenseNote(expenseNote);
    setExpenseDate(expenseDate);
    setExpenseDescription(expenseDescription);
    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      const loadingJustification = toast.loading(
        "Fetching justification documents..."
      );

      const response = await apiDefinitions.getJustificationForCampaignById(
        token,
        expenseId
      );
      const { data, code } = response.data;

      if (code === 200) {
        toast.dismiss(loadingJustification);
        const transformedData = data.map((filePath: string, index: number) => {
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "Unknown File";
          const fileExtension =
            decodedFilePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "excel";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        });

        setAttendaceDocuments(transformedData);
        setIsJustificationModalOpen(true);
      } else {
        toast.dismiss(loadingJustification);
        console.error("Failed to fetch justification documents.");
      }
    } catch (error) {
      console.error("Error fetching justification documents:", error);
    }
  };

  const [displayTopUpNote, setDisplayTopUpNote] = useState("");
  const [displayTopUpDate, setDisplayTopUpDate] = useState("");

  const handleViewTopUpJustification = async (
    expenseId: number,
    expenseNote: string,
    expenseDate: string
  ) => {
    console.log("View Justification for Top-Up ID:", expenseId);
    setDisplayTopUpNote(expenseNote);
    setDisplayTopUpDate(expenseDate);

    try {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      const loadingJustification = toast.loading(
        "Fetching justification documents..."
      );

      const response = await apiDefinitions.viewTopUpJustification(
        token,
        expenseId
      );
      const { data, code } = response.data;

      if (code === 200) {
        toast.dismiss(loadingJustification);
        const transformedData = data.topUpFileJustifications.map(
          (filePath: string, index: number) => {
            const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
            const fileName = decodedFilePath.split("/").pop() || "Unknown File";
            const fileExtension =
              decodedFilePath.split(".").pop()?.toLowerCase() || "";

            let fileType = "unknown";

            if (fileExtension === "pdf") {
              fileType = "pdf";
            } else if (
              fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)
            ) {
              fileType = "image";
            } else if (fileExtension.match(/(xls|xlsx)$/)) {
              fileType = "excel";
            } else if (fileExtension.match(/(doc|docx)$/)) {
              fileType = "doc";
            }

            return {
              id: index, // Use index as a unique identifier
              filePath: filePath,
              fileName: fileName,
              fileType: fileType,
              fileExtension: fileExtension,
            };
          }
        );

        setTopUpJustificationDocuments(transformedData);
        setIsTopUpJustificationModalOpen(true);
      } else {
        toast.dismiss(loadingJustification);
        console.error("Failed to fetch top-up justification documents.");
      }
    } catch (error) {
      console.error("Error fetching top-up justification documents:", error);
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (id) {
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          apiDefinitions
            .getExpensesListById(token, id)
            .then((response: any) => {
              const { data } = response.data;
              console.log("Expenses List", data);

              // Map only the required fields
              const mappedExpenses = data.map((item: any) => ({
                id: item.id,
                expenseTypeName: item.expenseTypeName,
                amount: item.amount,
                approveStatusName: item.approveStatusName,
                typeReason: item.typeReason,
                expenseType: item.expenseTypeName,
                approveRejectDate: item.approveRejectDate,
                note: item.note,
                date: item.date,
              }));

              setExpenses(mappedExpenses);
            });
        } else {
          console.log("Session expired. Please log in again.");
          navigate(route.login);
        }
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [id, refresh]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (id) {
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          apiDefinitions.getTopUpList(token, id).then((response: any) => {
            const { data } = response.data;
            console.log("Expenses List", data);

            // Map only the required fields
            const mappedTopup = data.map((item: any) => ({
              id: item.id,
              topUpAmount: item.topUpAmount,
              topUpBy: item.topUpBy,
              topUpDate: item.topUpDate,
              status: item.status,
              statusId: item.status[0].id,
              statusName: item.status[0].name,
              statusColor: item.status[0].color,
              note: item.note,
              topUpApproveRejectDate: item.topUpApproveRejectDate,
            }));

            setTopUp(mappedTopup);
          });
        } else {
          console.log("Session expired. Please log in again.");
          navigate(route.login);
        }
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [id, refresh]);

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  const handleTopUpFileSelect = (files: any) => {
    setUploadedTopUpFile(files);
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  useEffect(() => {
    if (clearFileTopUploader) {
      setClearFileTopUploader(false);
    }
  }, [clearFileTopUploader]);

  const handleSubmit = () => {
    let isValid = true;

    if (expenseType === "") {
      setIsTypeValid(true);
      isValid = false;
    } else {
      setIsTypeValid(false);
    }

    if (expenseName === "") {
      setIsExpenseNameValid(true);
      isValid = false;
    } else {
      setIsExpenseNameValid(false);
    }

    if (
      expenseValue === "" ||
      isNaN(Number(expenseValue)) ||
      Number(expenseValue) <= 0
    ) {
      setIsExpenseValueValid(true);
      isValid = false;
    } else {
      setIsExpenseValueValid(false);
    }

    if (uploadedFile.length === 0) {
      setIsFileUploadValid(false);
      isValid = false;
    } else {
      setIsFileUploadValid(true);
    }

    if (isValid) {
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        expenseTypes: [
          {
            id: parseInt(expenseType, 10),
          },
        ],
        amount: expenseValue,
        typeReason: expenseName,
        requestBy: employeeId,
      };
      formData.append("campaignExpensesDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want To Add a New Expense?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Add Expense",
        denyButtonText: `Do Not Add Expense`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Adding Expenses...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createExpenses(token, id, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Expense Added Successfully", {
                      duration: 5000,
                    });
                    handleClear();
                    setReload(!reload);
                    setRefresh(!refresh);
                    window.location.reload();
                    // const modalElement =
                    //   document.getElementById("con-close-modal");
                    // if (modalElement) {
                    //   // Hide the modal
                    //   modalElement.style.display = "none";

                    //   document.body.classList.remove("modal-open");

                    //   // Remove any overlay or backdrop
                    //   const modalBackdrop =
                    //     document.querySelector(".modal-backdrop");
                    //   if (modalBackdrop) {
                    //     modalBackdrop.remove();
                    //   }
                    // }
                  } else if (response.data.code === 400) {
                    toast.dismiss(toastId);
                    toast.error("Added Expense Exceeds the Allocated Budget");
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Add Expense");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          toast("Changes Were Not Saved", {
            icon: "⚠️",
          });
        }
      });
    }
  };

  const handleSubmitApproval = () => {
    let isValid = true;
    if (approvalStatus === "reject" && !approvalNote) {
      isValid = false;
      setIsNoteValid(true);
    } else {
      setIsNoteValid(false);
    }

    if (approvalStatus === "") {
      isValid = false;
      setIsApprovalStatusValid(true);
    } else {
      setIsApprovalStatusValid(false);
    }

    if (!isValid) {
      console.error("Form validation failed.");
      return; // Stop execution if validation fails
    }
    console.log("Submitted");
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }
    if (isValid) {
      Swal.fire({
        title: "Do You Want To Submit?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }
          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);
            if (decodedToken.exp && decodedToken.exp > currentTime) {
              apiDefinitions
                .campaignExpensesApproval(
                  token,
                  expenseId,
                  approvalStatus,
                  employeeId,
                  approvalNote
                )
                .then((response: any) => {
                  const data = response.data.data;
                  console.log("Lead Data By ID: ", data);
                  setRefresh(!refresh);
                  // Close the modal
                  // const modalElement = document.getElementById("add_faq");
                  // if (modalElement) {
                  //   modalElement.classList.remove("show");
                  //   modalElement.style.display = "none";
                  //   const backdrop = document.querySelector(".modal-backdrop");
                  //   if (backdrop) {
                  //     backdrop.remove();
                  //   }
                  // }
                  window.location.reload();
                  // Show success Swal
                  Swal.fire({
                    icon: "success",
                    title: "Approval/ Rejection Submitted Successfully!",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                })
                .catch((error) => {
                  console.error("Error approving lead:", error);
                  Swal.fire({
                    icon: "error",
                    title: "Failed to Save Changes",
                    text: "An error occurred while saving changes. Please try again.",
                  });
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate("/login");
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          // Show toast for not saving
          toast("Changes Were Not Saved", {
            icon: "⚠️",
          });
        }
      });
    }
  };

  const handleClearApproval = () => {
    setApprovalNote("");
    setApprovalStatus("");
  };

  const handleTopUp = () => {
    console.log("Top Up");

    let isValid = true;

    if (budget === "" || isNaN(Number(budget)) || Number(budget) <= 0) {
      setIsTopUpValid(true);
      isValid = false;
    } else {
      setIsTopUpValid(false);
    }

    if (note === "") {
      setIsNoteValid(true);
      isValid = false;
    } else {
      setIsNoteValid(false);
    }

    if (uploadedTopUpFile.length === 0) {
      setIsTopUpFileValid(false);
      isValid = false;
    } else {
      setIsTopUpFileValid(true);
    }

    if (isValid) {
      const formData = new FormData();

      if (uploadedTopUpFile && uploadedTopUpFile.length > 0) {
        uploadedTopUpFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        topUpAmount: budget,
        topUpBy: employeeId,
        reason: note,
      };
      formData.append("topUpCampaignDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want To Top-Up This Campaign?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Top Up",
        denyButtonText: `Do Not Top Up`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Adding Top-Up...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .topUpCampaign(token, id, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Campaign Top-Up Successful", {
                      duration: 5000,
                    });
                    handleTopClear();
                    setReload(!reload);
                    setRefresh(!refresh);
                    window.location.reload();
                    // const modalElement =
                    //   document.getElementById("topup-close-modal");
                    // if (modalElement) {
                    //   // Hide the modal
                    //   modalElement.style.display = "none";

                    //   document.body.classList.remove("modal-open");

                    //   // Remove any overlay or backdrop
                    //   const modalBackdrop =
                    //     document.querySelector(".modal-backdrop");
                    //   if (modalBackdrop) {
                    //     modalBackdrop.remove();
                    //   }
                    // }
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Top-Up Campaign");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          toast("Changes Were Not Saved", {
            icon: "⚠️",
          });
        }
      });
    }
  };

  const handleDeleteExpense = (expenseId: number) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = sessionStorage.getItem("token");
        if (!token) {
          console.error("No token found. Please log in again.");
          return;
        }

        const toastId = toast.loading("Deleting Expenses...");

        apiDefinitions
          .deleteExpensesById(token, expenseId)
          .then((response: any) => {
            if (response.data.code === 200) {
              toast.dismiss(toastId);
              setRefresh(!refresh);
              toast.success("Expenses Deleted Successfully", {
                duration: 5000,
              });
            } else if (response.data.code === 400) {
              toast.dismiss(toastId);
              toast.error("Non-pending expenses cannot be deleted");
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Delete Expense");
            }
          })
          .catch((error) => {
            toast.dismiss(toastId);
            console.error("Error deleting expense:", error);
            Swal.fire("Error", "Failed to delete the expense.", "error");
          });
      }
    });
  };

  const handleDeleteTopUp = (expenseId: number) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const token = sessionStorage.getItem("token");
        if (!token) {
          console.error("No token found. Please log in again.");
          return;
        }

        const toastId = toast.loading("Deleting Top-Up...");

        apiDefinitions
          .deleteTopUp(token, expenseId)
          .then((response: any) => {
            if (response.data.code === 200) {
              toast.dismiss(toastId);
              setRefresh(!refresh);
              toast.success("Top-Up Deleted Successfully", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Delete Top-Up");
            }
          })
          .catch((error) => {
            toast.dismiss(toastId);
            console.error("Error deleting top-up:", error);
            Swal.fire("Error", "Failed to delete the top-up.", "error");
          });
      }
    });
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasImageLoaded.current) {
      hasImageLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getJustificationForCampaignById(token, viewJustificationById)
          .then((response: any) => {
            if (response.data.code === 200) {
              setUploadAttendaceDocuments(response.data.data);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (uploadAttendaceDocuments !== null) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is null
      // You might want to set some default value or handle it based on your requirements
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments, refresh]);

  useEffect(() => {
    const handleModalClose = () => {
      document.body.classList.remove("modal-open");
    };

    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, []);

  const handleTopClear = () => {
    setBudget("");
    setNote("");
    setUploadedTopUpFile([]);
    setClearFileTopUploader(true);
    setIsTopUpFileValid(true);
    setIsTopUpValid(false);
    setIsNoteValid(false);
  };

  interface ReasonPopoverProps {
    reason: string;
  }

  const renderReasonPopover = ({ reason }: ReasonPopoverProps): JSX.Element => (
    <Popover id="popover-reason">
      <Popover.Body>{reason}</Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-10">
                    <h4 className="page-title">View Campaign Details</h4>
                  </div>
                  <div className="col-2 text-end">
                    <button className="btn btn-primary" onClick={handleBack}>
                      <i
                        className="feather-arrow-left"
                        style={{ fontSize: "17px", marginRight: "5px" }}
                      />
                      Back to list
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Card
                    className="campaign-details-card shadow-sm border-0"
                    style={{ maxHeight: "820px", overflowY: "auto" }}
                  >
                    <Card.Body>
                      <div className="row">
                        <div className="col-md-8">
                          <h3 className="campaign-details-header mb-4">
                            Campaign Details
                          </h3>
                        </div>
                      </div>
                      <div className="campaign-info mb-4">
                        <h5 className="mb-2 text-primary">
                          General Information
                        </h5>
                        <p>
                          <strong>Name:</strong> {name}
                        </p>
                        <p>
                          <strong>Campaign Type:</strong> {campaignTypeName}
                        </p>
                        <p>
                          <strong>Campaign Sub-Type:</strong>{" "}
                          {campaignSubTypeName}
                        </p>

                        <p>
                          <strong>Goal:</strong> {goal}
                        </p>
                        <p>
                          <strong>Expected Lead Count:</strong> {leadCount}
                        </p>
                        <p>
                          <strong>Description:</strong> {description}
                        </p>
                        <p>
                          <strong>Selected Sub-Budget:</strong> {subBudget}
                        </p>
                        <p>
                          <strong>Allocated Budget(LKR): </strong>{" "}
                          {allocatedBudget}
                        </p>
                        <p>
                          <strong>Total Expenses(LKR): </strong> {totalExpenses}
                        </p>
                        <p>
                          <strong>Remaining Budget(LKR): </strong>{" "}
                          {remainingBudget}
                        </p>
                      </div>
                      <div className="divider"></div>
                      <div className="campaign-dates mb-4">
                        <h5 className="mb-2 text-primary">Timeline</h5>
                        <p>
                          <strong>Start Date:</strong> {startDate}
                        </p>
                        <p>
                          <strong>End Date:</strong> {endDate}
                        </p>
                      </div>
                      <div className="divider"></div>
                      <div className="campaign-audience mb-4">
                        <h5 className="mb-2 text-primary">Target Audience</h5>
                        <ul className="list-unstyled">
                          {targetAudience.map((audience, index) => (
                            <li key={index} className="mb-1">
                              <span className="badge bg-secondary">
                                {audience.label}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="divider"></div>
                      <div className="campaign-channels">
                        <h5 className="mb-4 text-primary">Channels</h5>
                        <div className="row">
                          {channels.map((channel, channelIndex) => (
                            <div key={channelIndex} className="col-md-6 mb-4">
                              <div className="d-flex justify-content-between align-items-center border p-3 rounded">
                                <span className="fw-bold">{channel.name}</span>
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  placement="right"
                                  overlay={renderPopover(channel)}
                                >
                                  <Button variant="outline-primary">
                                    View
                                  </Button>
                                </OverlayTrigger>
                              </div>
                              <div className="mt-2">
                                <strong>People Involved:</strong>
                                <ul className="list-unstyled ms-3">
                                  {channel.people.map((person, personIndex) => (
                                    <li
                                      key={personIndex}
                                      className="text-secondary"
                                    >
                                      {person.name_in_full
                                        .split(" ")
                                        .slice(0, 3)
                                        .join(" ")}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-6">
                  <div className="column">
                    <div className="col-md-12">
                      {/* <Card>
                    <Card.Body> */}
                      <div className="col-md-12 d-flex">
                        <div
                          className="card flex-fill"
                          style={{ maxHeight: "410px", overflowY: "auto" }}
                        >
                          <div className="card-header border-0 pb-0">
                            <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                              <h4>
                                <i className="ti ti-grip-vertical me-1" />
                                Expenses List
                              </h4>
                              {/* Add Expenses Button */}
                              {campaignStatus === "active" && (
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light mt-1 me-1"
                                  data-bs-toggle="modal"
                                  data-bs-target="#con-close-modal"
                                >
                                  Add Expenses
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive custom-table">
                              <table
                                className="table dataTable"
                                id="deals-project"
                              >
                                <thead className="thead-light">
                                  <tr>
                                    {/* <th>Expenses Name</th> */}
                                    <th>Expenses Type</th>
                                    <th>Expenses Creation Date</th>
                                    <th>Expenses Value(LKR)</th>
                                    <th>Approval Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expenses.map((expense) => (
                                    <tr key={expense.id}>
                                      {/* <td>
                                        {expense.typeReason.length > 20 ? (
                                          <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={renderReasonPopover({
                                              reason: expense.typeReason,
                                            })}
                                          >
                                            <span
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              {expense.typeReason.substring(
                                                0,
                                                20
                                              )}
                                              ...
                                            </span>
                                          </OverlayTrigger>
                                        ) : (
                                          expense.typeReason
                                        )}
                                      </td> */}
                                      <td>{expense.expenseType}</td>
                                      <td>{expense.date}</td>
                                      <td>{expense.amount}</td>
                                      <td>
                                        <span
                                          className={`badge bg-soft-${
                                            expense.approveStatusName ===
                                            "Pending"
                                              ? "secondary"
                                              : expense.approveStatusName ===
                                                "Approved"
                                              ? "success"
                                              : "danger"
                                          }`}
                                        >
                                          {expense.approveStatusName}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-danger btn-sm me-2"
                                          onClick={() =>
                                            handleDeleteExpense(expense.id)
                                          }
                                          disabled={
                                            expense.approveStatusName !==
                                            "Pending"
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>

                                        <button
                                          className="btn btn-success btn-sm me-2"
                                          data-bs-toggle="modal"
                                          data-bs-target="#add_faq"
                                          onClick={() =>
                                            handleApproveExpense(
                                              expense.id,
                                              expense.typeReason,
                                              expense.amount,
                                              expense.expenseType
                                            )
                                          }
                                          disabled={
                                            expense.approveStatusName !==
                                            "Pending"
                                          }
                                        >
                                          <i className="feather-check-circle"></i>
                                        </button>

                                        <button
                                          className="btn btn-secondary btn-sm"
                                          onClick={() =>
                                            handleViewJustification(
                                              expense.id,
                                              expense.note,
                                              expense.approveRejectDate,
                                              expense.typeReason
                                            )
                                          }
                                        >
                                          <i className="feather-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Card.Body>
                  </Card> */}
                    </div>

                    <div className="col-md-12">
                      <div className="col-md-12 d-flex">
                        <div
                          className="card flex-fill"
                          style={{ maxHeight: "385px", overflowY: "auto" }}
                        >
                          <div className="card-header border-0 pb-0">
                            <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                              <h4>
                                <i className="ti ti-grip-vertical me-1" />
                                Top-Up List
                              </h4>
                              {/* Add Expenses Button */}
                              {campaignStatus === "active" && (
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light mt-1 me-1"
                                  data-bs-toggle="modal"
                                  data-bs-target="#topup-close-modal"
                                >
                                  Top Up Campaign
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="card-body">
                            {/* Scrollable Table */}
                            <div
                              className="table-responsive custom-table"
                              style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                              <table
                                className="table dataTable"
                                id="deals-project"
                              >
                                <thead
                                  className="thead-light"
                                  style={{
                                    position: "sticky",
                                    top: 0,
                                    backgroundColor: "#f8f9fa",
                                    zIndex: 1,
                                  }}
                                >
                                  <tr>
                                    <th>Top-Up Amount(LKR)</th>
                                    <th>Top-Up Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {topUp.map((expense) => (
                                    <tr key={expense.id}>
                                      <td>{expense.topUpAmount}</td>
                                      <td>{expense.topUpDate}</td>
                                      <td>
                                        <span
                                          className={`badge bg-soft-${expense.statusColor}`}
                                        >
                                          {expense.statusName}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-danger btn-sm me-2"
                                          disabled={expense.statusId === 1}
                                          onClick={() =>
                                            handleDeleteTopUp(expense.id)
                                          }
                                        >
                                          <i className="fa fa-trash"></i>
                                        </button>

                                        <button
                                          className="btn btn-secondary btn-sm"
                                          onClick={() =>
                                            handleViewTopUpJustification(
                                              expense.id,
                                              expense.note,
                                              expense.topUpApproveRejectDate
                                            )
                                          }
                                        >
                                          <i className="feather-eye"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="con-close-modal"
        tabIndex={-1}
        aria-labelledby="con-close-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="con-close-modal-label">
                Add Expenses
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClear}
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12 mb-3 mt-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Expense Type
                  </label>
                  <select
                    className={`form-select ${isTypeValid ? "is-invalid" : ""}`}
                    id="validationCustomContactMethod"
                    value={expenseType}
                    onChange={(e) => setExpenseType(e.target.value)}
                    required
                  >
                    <option value="">Select an option</option>
                    {coursesCategoryList.map((courseCategory) => (
                      <option key={courseCategory.id} value={courseCategory.id}>
                        {courseCategory.name}
                      </option>
                    ))}
                  </select>
                  {isTypeValid && (
                    <div className="invalid-feedback">
                      Please select a expense type.
                    </div>
                  )}
                </div>

                <div className="col-md-12 mb-3">
                  <label className="col-form-label">
                    Expense Description <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      value={expenseName}
                      type="text"
                      className={`form-control ${
                        isExpenseNameValid ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setExpenseName(e.target.value)}
                      placeholder="Expense Description"
                    />
                    {isExpenseNameValid && (
                      <div className="invalid-feedback">
                        Please enter the expense name.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="col-form-label">
                    Expense Value (Rs.) <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      value={expenseValue}
                      type="text"
                      className={`form-control ${
                        isExpenseValueValid ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setExpenseValue(e.target.value)}
                      placeholder="Expense Value"
                    />
                    {isExpenseValueValid && (
                      <div className="invalid-feedback">
                        Please enter a valid expense value.
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <FileUpload
                    onFileSelect={handleFileSelect}
                    clear={clearFileUploader}
                  />
                  {!isFileUploadValid && (
                    <div className="text-danger mt-2">
                      Please upload at least one file.
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-cancel waves-effect"
                  data-bs-dismiss="modal"
                  onClick={handleClear}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={handleSubmit}
                >
                  Add Expense
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="topup-close-modal"
        tabIndex={-1}
        aria-labelledby="con-close-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="con-close-modal-label">
                Top Up Campaign
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleTopClear}
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="field-2" className="form-label">
                    Top Up Amount
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      LKR
                    </span>
                    <input
                      type="text"
                      className={`form-control ${
                        isTopUpValid ? "is-invalid" : ""
                      }`}
                      id="field-2"
                      placeholder="50000.00"
                      aria-describedby="basic-addon1"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    />
                    {isTopUpValid && (
                      <div className="invalid-feedback">
                        Please enter a valid top-up value.
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 mt-3">
                    <label htmlFor="note" className="form-label">
                      Note
                    </label>
                    <textarea
                      className={`form-control ${
                        isNoteValid ? "is-invalid" : ""
                      }`}
                      id="note"
                      rows={3}
                      placeholder="Enter your note here"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                    {isNoteValid && (
                      <div className="invalid-feedback">
                        Please enter the note.
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <SingleFileUpload
                    onFileSelect={handleTopUpFileSelect}
                    clear={clearFileTopUploader}
                  />
                  {!isTopUpFileValid && (
                    <div className="text-danger mt-2">
                      Please upload at least one file.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                data-bs-dismiss="modal"
                onClick={handleTopClear}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleTopUp}
              >
                Add Top-Up
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="add_faq" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Expenses Approval</h5>
              <div className="d-flex align-items-center mod-toggle">
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleClearApproval();
                  }}
                >
                  <i className="ti ti-x" />
                </button>
              </div>
            </div>
            <form>
              <div className="modal-body">
                {/* <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Approval Note
                  </label>
                  <textarea
                    rows={1}
                    cols={5}
                    className={`form-control ${
                      isNoteValid ? "is-invalid" : ""
                    }`}
                    placeholder="Enter text here"
                    defaultValue={""}
                    onChange={(e) => setApprovalNote(e.target.value)}
                    value={approvalNote}
                  />
                  {isNoteValid && (
                    <div className="invalid-feedback">
                      Please enter a note.
                    </div>
                  )}
                </div> */}
                <p>
                  <strong>Expense Type:</strong> {displayExpenseType}
                </p>
                <p>
                  <strong>Expense Description:</strong> {displayExpenseName}
                </p>
                <p>
                  <strong>Expense Value(LKR):</strong> {displayExpenseValue}
                </p>

                <div className="divider"></div>
                <div className="mb-3">
                  <label
                    className="form-label"
                    htmlFor="validationCustomContactMethod"
                  >
                    Expenses Approval Status
                  </label>
                  <select
                    className={`form-select ${
                      isApprovalStatusValid ? "is-invalid" : ""
                    }`}
                    id="validationCustomContactMethod"
                    value={approvalStatus}
                    onChange={(e) => setApprovalStatus(e.target.value)}
                    required
                  >
                    <option value="">Select an option</option>
                    <option value="approve">Approve </option>
                    <option value="reject">Reject</option>
                  </select>
                  {isApprovalStatusValid && (
                    <div className="invalid-feedback">
                      Please select an approval status.
                    </div>
                  )}
                </div>

                {approvalStatus === "reject" && (
                  <div className="mb-3">
                    <label
                      className="form-label"
                      htmlFor="validationCustomContactMethod"
                    >
                      Rejection Note
                    </label>
                    <textarea
                      rows={1}
                      cols={5}
                      className={`form-control ${
                        isNoteValid ? "is-invalid" : ""
                      }`}
                      placeholder="Enter text here"
                      defaultValue={""}
                      onChange={(e) => setApprovalNote(e.target.value)}
                      value={approvalNote}
                    />
                    {isNoteValid && (
                      <div className="invalid-feedback">
                        Please enter an approval note.
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <div className="d-flex align-items-center justify-content-end m-0">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-light me-2"
                    onClick={() => {
                      handleClearApproval();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    // data-bs-dismiss="modal"
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmitApproval();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isJustificationModalOpen && (
        <>
          {/* Backdrop */}
          <div className="modal-backdrop fade show"></div>

          {/* Justification Documents Modal */}
          <div
            className="modal fade show"
            id="justification-modal"
            tabIndex={-1}
            aria-labelledby="justification-modal-label"
            aria-hidden={!isJustificationModalOpen}
            style={{ display: isJustificationModalOpen ? "block" : "none" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="justification-modal-label">
                    Justification Documents
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setIsJustificationModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <p>
                      <strong className="text">
                        Approval/ Rejection Note:
                      </strong>{" "}
                      {expenseNote ? expenseNote : "Not Available"}
                    </p>
                    <p>
                      <strong className="text">
                        Approval/ Rejection Date:
                      </strong>{" "}
                      {expenseDate ? expenseDate : "Not Available"}
                    </p>
                    <p>
                      <strong className="text">Expense Description:</strong>{" "}
                      {expenseDescription
                        ? expenseDescription
                        : "Not Available"}
                    </p>
                    <div className="divider"></div>
                    {attendaceDocuments.map(
                      (data: {
                        id: React.Key | null | undefined;
                        fileType: string;
                        fileName: any;
                        filePath: string | URL | undefined;
                      }) => (
                        <div className="col-md-4 mb-4" key={data.id}>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            {/* File Type Icon */}
                            <div className="mb-3">
                              {data.fileType === "pdf" ? (
                                <ImageWithBasePath
                                  src="/assets/img/pdf.png"
                                  className="img-fluid"
                                  alt="PDF Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "image" ? (
                                <ImageWithBasePath
                                  src="/assets/img/picture.png"
                                  className="img-fluid"
                                  alt="Image Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "doc" ? (
                                <ImageWithBasePath
                                  src="/assets/img/doc.png"
                                  className="img-fluid"
                                  alt="Doc Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "xls" ? (
                                <ImageWithBasePath
                                  src="/assets/img/xls.png"
                                  className="img-fluid"
                                  alt="XLS Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : null}
                            </div>
                            {/* File Details */}
                            <div className="text-center">
                              <h6
                                className="mb-2 text-truncate"
                                style={{
                                  maxWidth: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                title={data.fileName}
                              >
                                {data.fileName || "Placeholder Name"}
                              </h6>
                            </div>
                            {/* Action Buttons */}
                            <div className="d-flex justify-content-center">
                              <a
                                href={data.filePath?.toString()}
                                download={data.fileName}
                                className="btn btn-outline-primary me-2 btn-sm"
                              >
                                Download File
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsJustificationModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModalScrollable3"
            tabIndex={-1}
            aria-labelledby="exampleModalScrollable3"
            data-bs-keyboard="false"
            aria-hidden="true"
          >
            {/* Scrollable modal */}
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" id="staticBackdropLabel3">
                    Request Details
                  </h4>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body"></div>
                <div className="modal-footer"></div>
              </div>
            </div>
          </div>
        </>
      )}

      {isTopUpJustificationModalOpen && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            className="modal fade show"
            id="top-up-justification-modal"
            tabIndex={-1}
            aria-labelledby="top-up-justification-modal-label"
            aria-hidden={!isTopUpJustificationModalOpen}
            style={{
              display: isTopUpJustificationModalOpen ? "block" : "none",
            }}
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title"
                    id="top-up-justification-modal-label"
                  >
                    Top-Up Justification Documents
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setIsTopUpJustificationModalOpen(false)}
                  ></button>
                </div>
                <div className="modal-body">
                  <p>
                    <strong className="text">
                      Top-Up Approval/Rejection Date:
                    </strong>{" "}
                    {displayTopUpDate}
                  </p>
                  <p>
                    <strong className="text">
                      Top-Up Approval/Rejection Note:
                    </strong>{" "}
                    {displayTopUpNote}
                  </p>
                  <div className="divider"></div>
                  <div className="row">
                    {topUpJustificationDocuments.map(
                      (data: {
                        id: React.Key | null | undefined;
                        fileType: string;
                        fileName: any;
                        filePath: string | URL | undefined;
                      }) => (
                        <div className="col-md-4 mb-4" key={data.id}>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            <div className="mb-3">
                              {data.fileType === "pdf" ? (
                                <ImageWithBasePath
                                  src="/assets/img/pdf.png"
                                  className="img-fluid"
                                  alt="PDF Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "image" ? (
                                <ImageWithBasePath
                                  src="/assets/img/picture.png"
                                  className="img-fluid"
                                  alt="Image Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "doc" ? (
                                <ImageWithBasePath
                                  src="/assets/img/doc.png"
                                  className="img-fluid"
                                  alt="Doc Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : data.fileType === "xls" ? (
                                <ImageWithBasePath
                                  src="/assets/img/xls.png"
                                  className="img-fluid"
                                  alt="XLS Icon"
                                  style={{ width: "35px", height: "auto" }}
                                />
                              ) : null}
                            </div>
                            <div className="text-center">
                              <h6
                                className="mb-2 text-truncate"
                                style={{
                                  maxWidth: "150px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                title={data.fileName}
                              >
                                {data.fileName || "Placeholder Name"}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-center">
                              <a
                                href={data.filePath?.toString()}
                                download={data.fileName}
                                className="btn btn-outline-primary me-2 btn-sm"
                              >
                                Download File
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setIsTopUpJustificationModalOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewCampaign;
