import React, { useEffect, useState } from "react";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { Pagination, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const ViewCallHistory = () => {
  const route = all_routes;
  const navigate = useNavigate();

  const [callHistory, setCallHistory] = useState<Lead[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [days, setDays] = useState("7");
  const pagesToShow = 10;
  const [desposition, setDesposition] = useState("");

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Lead {
    id: number;
    calldate: string;
    src: number;
    dst: number;
    dcontext: string;
    durationFormatted: number;
    disposition: string;
    cnam: string;
    cnum: string;
  }

  interface FetchLeadsResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  const fetchLeads = async (
    token: string,
    page: number,
    itemsPerPage: number,
    desposition: string,
    days: string
  ): Promise<void> => {
    const offset = page - 1;
    setLoading(true);

    try {
      const response = await apiDefinitions.getCallHistory(
        token,
        offset,
        itemsPerPage,
        desposition,
        days
      );
      const data: FetchLeadsResponse = response.data;
      setCallHistory(response.data.data.content);
      setTotalPages(response.data.data.totalPages);

      setLoading(false); // Set loading to false once the data is fetched
    } catch (error) {
      console.error("Failed to fetch leads", error);
      setLoading(false); // Ensure loading is set to false even if the request fails
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    const loadingToastId = toast.loading("Validating session...");

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        toast.update(loadingToastId, {
          render: "Session valid, fetching leads...",
          type: "info",
          isLoading: true,
          autoClose: 2000,
        });

        fetchLeads(token, currentPage, itemsPerPage, desposition, days);
      } else {
        toast.update(loadingToastId, {
          render: "Session expired. Please log in again.",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        navigate(route.login);
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Invalid token. Please log in again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      console.error("Invalid token or unable to decode", error);
    }
  }, [currentPage, itemsPerPage, navigate, route.login, days, desposition]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const getPageRange = () => {
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const handleClearFilters = () => {
    setDesposition("");
    setDays("7");
    setCurrentPage(1);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header justify-content-between">
                  <div className="card-title">Call History</div>
                  <div className="row">
                    <div className="col-md-1 mt-3">
                      <Form.Select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        aria-label="Select number of items per page"
                        className="w-auto"
                        style={{ marginTop: 10 }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </Form.Select>
                    </div>
                    <div className="col-md-2 mt-4">
                      <select
                        className={`form-select`}
                        id="validationCustomContactMethod"
                        value={days}
                        onChange={(e) => setDays(e.target.value)}
                      >
                        <option value="7">Past 7 Days</option>
                        <option value="15">Past 15 Days</option>
                        <option value="30">Past 30 Days</option>
                      </select>
                    </div>
                    <div className="col-md-3 mt-4">
                      <select
                        className={`form-select`}
                        id="validationCustomContactMethod"
                        value={desposition}
                        onChange={(e) => setDesposition(e.target.value)}
                      >
                        <option value="">Select A Disposition</option>
                        <option value="ANSWERED">Answered</option>
                        <option value="NO ANSWER">Not Answered</option>
                        <option value="FAILED">Failed</option>
                        <option value="BUSY">Busy</option>
                      </select>
                    </div>
                    <div className="col-md-6 mt-4 text-end">
                      <button
                        className="btn btn-primary"
                        onClick={handleClearFilters}
                      >
                        Clear Filters
                      </button>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive">
                    {loading ? (
                      // Table Loading State
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : (
                      <table className="table text-nowrap table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Call Date & Time</th>
                            <th scope="col">Call From</th>
                            <th scope="col">Call To</th>
                            <th scope="col">Call Type</th>
                            <th scope="col">Department</th>
                            <th scope="col">Dept. Call No.</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Disposition</th>
                          </tr>
                        </thead>
                        <tbody>
                          {callHistory.map((lead, index) => (
                            <tr key={index}>
                              <td>{lead.calldate}</td>
                              <td>{lead.dst}</td>
                              <td>{lead.src}</td>
                              <td>
                                {lead.dcontext === "from-internal"
                                  ? "Internal"
                                  : lead.dcontext === "ext-local"
                                  ? "External"
                                  : lead.dcontext}
                              </td>
                              <td>{lead.cnam}</td>
                              <td>{lead.cnum}</td>
                              <td>{lead.durationFormatted}</td>
                              <td>
                                <span
                                  className={`badge bg-soft-${
                                    lead.disposition === "FAILED"
                                      ? "danger"
                                      : lead.disposition === "NO ANSWER"
                                      ? "secondary"
                                      : lead.disposition === "ANSWERED"
                                      ? "success"
                                      : lead.disposition === "BUSY"
                                      ? "danger"
                                      : "danger"
                                  }`}
                                >
                                  {lead.disposition || "N/A"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <Pagination className="mt-3 justify-content-center">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {getPageRange().map((page) => (
                      <Pagination.Item
                        key={page}
                        active={page === currentPage}
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCallHistory;
